import { RootState } from "../index";
import { KeyValues, StoreStatus } from "../../core/types/common.types";
import { ODSRow } from "./types";

export const hasErrorSelector = (state: RootState): boolean =>
  state.practices.errors && state.practices.errors ? true : false;

export const isBusySelector = (state: RootState): boolean =>
  state.practices.status === StoreStatus.BUSY;

export const valuesSelector = (state: RootState): KeyValues | null =>
  state.practices.values || null;

export const responseSelector = (state: RootState): ODSRow[] | null =>
  state.practices.response || null;

export const practiceSelector = (state: RootState): ODSRow | null =>
  state.practices.practice || null;
