import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/react";

export const useStyles = () => {
  const theme = useTheme();
  return {
    hero: css`
      background: #fff;
      color: #232932;
      padding: 0 0 0 0;
      z-index: 1;
      position: relative;
      overflow: hidden;

      ${theme.breakpoints.up("md")} {
        background: url(/images/womanuk.png) calc(50% + 340px) 100% no-repeat,
          url(/images/bokeh.jpeg) top left no-repeat,
          linear-gradient(
            180deg,
            ${theme.palette.grey[100]} 0%,
            rgba(255, 255, 255, 1) 100%
          );
        background-size: auto 100%, cover;
      }

      ${theme.breakpoints.down("md")} {
        background: url(/images/bokeh.jpeg) top left no-repeat,
          linear-gradient(
            180deg,
            ${theme.palette.grey[100]} 0%,
            rgba(255, 255, 255, 1) 100%
          );
        background-size: cover;
      }
    `,
    heroTextWrap: css`
      padding: 5rem 0;
    `,
    heroButtonLeft: css`
      margin-right: 10px;
    `,
    light: css`
      font-weight: 100;
      display: block;
    `,
    heroImageWrap: css`
      display: flex;
      align-items: flex-start;
      margin: 0 -100px 0 -100px;
      padding: 35px 0;
    `,
    heroImageWrapPullUp: css`
      margin-top: -90px;
    `,
    heroImage: css`
      flex-basis: 413px;
      flex-grow: 1;
      margin: 0 5px 0 5px;
      height: auto;

      img {
        width: 100%;
        border-radius: 3rem 0.5rem 3rem 0.5rem;
        display: block;
      }
    `,
    heroImage1: css`
      margin-top: -15px;
    `,
    heroImage2: css`
      margin-top: 35px;
    `,
    heroImage3: css`
      margin-top: -35px;
    `,
    heroImage4: css`
      margin-top: 25px;
    `,
  };
};
