import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../core/constants";
import MainLayout from "../../../../layouts/Main";
import { useAppDispatch } from "../../../../store";
import { searchPracticeThunk } from "../../../../store/practices";
import FAQStrip from "./components/FAQStrip";
import GeneticDataStrip from "./components/GeneticDataStrip";
import HeroUK from "./components/HeroUK";
import InvolvementStrip from "./components/InvolvementStrip";
import TakePartStrip from "./components/TakePartStrip";
import PostEnrolmentStrip from "./components/PostEnrolmentStrip";
import PrivacyStrip from "./components/PrivacyStrip";

function UnitedKingdomMainScene() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSearch = (keyword: string) => {
    dispatch(searchPracticeThunk(keyword));
    navigate(ROUTES.unitedKingdomResults);
  };

  return (
    <MainLayout>
      <HeroUK handleSearch={handleSearch} />
      <TakePartStrip />
      <InvolvementStrip />
      <PostEnrolmentStrip />
      <GeneticDataStrip />
      <FAQStrip />
      <PrivacyStrip />
    </MainLayout>
  );
}

export default UnitedKingdomMainScene;
