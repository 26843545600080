import { ReactNode, useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Global } from "@emotion/react";
import { css } from "@emotion/react";
import PrivacyDialog from "../../components/PrivacyDialog";
import { COMPANY_NAME, COMPANY_ADDRESS } from "../../core/constants";
import { useSearchParams } from "react-router-dom";
import TermsDialog from "../../components/TermsDialog";
import MainLayoutContext from "./MainLayoutContext";

export type MainLayoutProps = {
  children: ReactNode;
};

const globalStyles = {
  body: css`
    padding: 94px 0 0 0;
  `,
  "#root": css`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  `,
  main: css`
    flex: 1;
  `,
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const [searchParams] = useSearchParams();
  const privacyOverride = searchParams.get("privacy");
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const handleCloseTerms = () => {
    setIsTermsOpen(false);
  };

  const handleOpenTerms = () => {
    setIsTermsOpen(true);
  };
  const handleClosePrivacy = () => {
    setPrivacyOpen(false);
  };

  const handleOpenPrivacy = () => {
    setPrivacyOpen(true);
  };

  useEffect(() => {
    if (privacyOverride) {
      setPrivacyOpen(true);
    }
  }, [privacyOverride]);

  return (
    <MainLayoutContext.Provider
      value={{
        isTermsOpen,
        isPrivacyOpen,
        handleCloseTerms,
        handleOpenTerms,
        handleClosePrivacy,
        handleOpenPrivacy,
      }}
    >
      <Global styles={globalStyles} />
      <Header />

      <main>{children}</main>

      <Footer
        companyName={COMPANY_NAME}
        address={COMPANY_ADDRESS}
        onPrivacyOpen={() => {
          setPrivacyOpen(true);
        }}
      />
      <PrivacyDialog
        isOpen={isPrivacyOpen}
        onClose={() => {
          setPrivacyOpen(false);
        }}
      />
      <TermsDialog isOpen={isTermsOpen} onClose={handleCloseTerms} />
    </MainLayoutContext.Provider>
  );
};

export const useMainLayout = () => useContext(MainLayoutContext);

export default MainLayout;
