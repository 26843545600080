import { createSlice } from "@reduxjs/toolkit";
import { PracticesState } from "./types";
import { StoreStatus } from "../../core/types/common.types";
import { searchPracticeThunk, getByOdsThunk } from "./thunks";

// Initial State
export const initialState: PracticesState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  response: null,
  practice: null,
};

// Slice
export const practicesSlice = createSlice({
  name: "practices",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers: {
    [searchPracticeThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
    },
    [searchPracticeThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    },
    [searchPracticeThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    },
    [getByOdsThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
      state.practice = null;
    },
    [getByOdsThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.practice = action.payload;
      state.errors = null;
    },
    [getByOdsThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.practice = null;
    },
  },
});

// Action creators
export const { reset } = practicesSlice.actions;
export * from "./thunks";
export default practicesSlice.reducer;
