import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { LINKS } from "../../core/constants";
import ListItemButton from "@mui/material/ListItemButton";

export const RegionDialog = ({ onClose, open }: any) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Please select your region:</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItemButton
          component={Link}
          href={LINKS.portalSA}
          onClick={() => handleClose}
          key={"South Africa"}
          target="_blank"
        >
          <ListItemAvatar>
            <Avatar src="/images/flagsa.png"></Avatar>
          </ListItemAvatar>
          <ListItemText primary={"South Africa"} />
        </ListItemButton>
        <ListItemButton
          component={Link}
          href={LINKS.portalUK()}
          onClick={() => handleClose}
          key={"United Kingdom"}
          target="_blank"
        >
          <ListItemAvatar>
            <Avatar src="/images/flaguk.png"></Avatar>
          </ListItemAvatar>
          <ListItemText primary={"United Kingdom"} />
        </ListItemButton>
      </List>
    </Dialog>
  );
};

export default RegionDialog;
