/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";
import AnimationOnScroll from "react-animate-on-scroll";
import { ROUTES } from "../../../../core/constants";
import { useStyles } from "./HeroHome.styles";

function HeroHome({ onOpenVideo }: any) {
  const styles = useStyles();
  const isFullWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );
  return (
    <section css={styles.hero}>
      <div css={styles.heroTextWrap}>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <Container>
            <Typography
              variant="h2"
              component="div"
              gutterBottom
              color="primary"
              css={styles.heroText}
            >
              <span css={styles.light}>Transforming</span> Healthcare{" "}
              <strong>Together</strong>
            </Typography>
            <Box mb={4}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                color="primary"
              >
                Discover Me is a research study that aims to analyse health and
                genetic information on thousands of people. The aim is to
                increase our understanding of many different diseases, and help
                improve patient care.
              </Typography>
            </Box>
            <Box
              mb={{ xs: 2, sm: 0 }}
              display="inline-block"
              width={isFullWidth ? "100%" : "auto"}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isFullWidth}
                component={Link}
                to={ROUTES.participants}
                css={styles.heroButtonLeft}
              >
                Participate
              </Button>
            </Box>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth={isFullWidth}
              startIcon={<PlayCircleIcon color="inherit" />}
              onClick={onOpenVideo}
            >
              Watch the video
            </Button>
          </Container>
        </AnimationOnScroll>
      </div>

      <div css={styles.heroImageWrap}>
        <div css={[styles.heroImage, styles.heroImage1]}>
          <AnimationOnScroll animateIn="animate__fadeInUp" delay={0}>
            <img src="/images/hero1.jpg" alt="hero1" />
          </AnimationOnScroll>
        </div>

        <div css={[styles.heroImage, styles.heroImage2]}>
          <AnimationOnScroll animateIn="animate__fadeInUp" delay={100}>
            <img src="/images/hero2.jpg" alt="hero2" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage3]}>
          <AnimationOnScroll animateIn="animate__fadeInUp" delay={200}>
            <img src="/images/hero6.jpg" alt="hero6" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage4]}>
          <AnimationOnScroll animateIn="animate__fadeInUp" delay={300}>
            <img src="/images/hero4.jpg" alt="hero4" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage1]}>
          <AnimationOnScroll animateIn="animate__fadeInUp" delay={400}>
            <img src="/images/hero5.jpg" alt="hero5" />
          </AnimationOnScroll>
        </div>
      </div>
      <div css={[styles.heroImageWrap, styles.heroImageWrapPullUp]}>
        <div css={[styles.heroImage, styles.heroImage1]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={0}
          >
            <img src="/images/hero10.jpg" alt="hero10" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage2]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={100}
          >
            <img src="/images/hero3.jpg" alt="hero3" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage3]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={200}
          >
            <img src="/images/hero7.jpg" alt="hero7" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage4]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={300}
          >
            <img src="/images/hero8.jpg" alt="hero8" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage1]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={400}
          >
            <img src="/images/hero11.jpg" alt="hero11" />
          </AnimationOnScroll>
        </div>
      </div>

      <div css={[styles.heroImageWrap, styles.heroImageWrapPullUp]}>
        <div css={[styles.heroImage, styles.heroImage1]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={0}
          >
            <img src="/images/hero10.jpg" alt="hero10" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage2]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={100}
          >
            <img src="/images/hero3.jpg" alt="hero3" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage3]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={200}
          >
            <img src="/images/hero12.jpg" alt="hero7" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage4]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={300}
          >
            <img src="/images/hero8.jpg" alt="hero8" />
          </AnimationOnScroll>
        </div>
        <div css={[styles.heroImage, styles.heroImage1]}>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            offset={-210}
            animateOnce={true}
            delay={400}
          >
            <img src="/images/hero11.jpg" alt="hero11" />
          </AnimationOnScroll>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
