interface VideoProps {
  video: string;
}

export const Video = ({ video }: VideoProps) => {
  return (
    <div
      data-testid="video"
      style={{
        alignSelf: "stretch",
        overflow: "hidden",
        height: "100%",
        paddingBottom: "56.25%",
        paddingTop: "35px",
        position: "relative",
      }}
    >
      <iframe
        title={"Discover Me"}
        src={video}
        frameBorder="0"
        allow="autoplay; fullscreen"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></iframe>
    </div>
  );
};

export default Video;
