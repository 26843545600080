import React, { ReactElement } from "react";
import Markdown from "react-markdown";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  DPO_EMAIL,
  ROUTES,
  SUPERVISORY_AUTHORITY_URL,
} from "../../../../../../core/constants";

export interface PrivacyDialogUKProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyDialogUK = ({
  isOpen,
  onClose,
}: PrivacyDialogUKProps): ReactElement => {
  const { t } = useTranslation("dmukprivacy");
  return (
    <Dialog
      data-testid="privacy-uk-dialog"
      fullScreen={false}
      open={isOpen}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="privacy-uk-dialog-title"
    >
      <DialogTitle id="privacy-uk-dialog-title">{t("title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" component="span">
          <Markdown>
            {t("section1.content", {
              dpoEmail: DPO_EMAIL,
            })}
          </Markdown>
        </Typography>
        <div>
          <Typography variant="h6" paragraph>
            {t("section2.title")}
          </Typography>
          <Typography variant="body2" component="span">
            <Markdown>{t("section2.content")}</Markdown>
          </Typography>
        </div>
        <div>
          <Typography variant="h6" paragraph>
            {t("section3.title")}
          </Typography>
          <Typography variant="body2" component="span">
            <Markdown>{t("section3.content")}</Markdown>
          </Typography>
        </div>
        <div>
          <Typography variant="h6" paragraph>
            {t("section4.title")}
          </Typography>
          <Typography variant="body2" component="span">
            <Markdown>{t("section4.content")}</Markdown>
          </Typography>
        </div>
        <div>
          <Typography variant="h6" paragraph>
            {t("section5.title")}
          </Typography>
          <Typography variant="body2" component="span">
            <Markdown>{t("section5.content")}</Markdown>
          </Typography>
        </div>
        <div>
          <Typography variant="h6" paragraph>
            {t("section6.title")}
          </Typography>
          <Typography variant="body2" component="span">
            <Markdown>{t("section6.content")}</Markdown>
          </Typography>
        </div>
        <div>
          <Typography variant="h6" paragraph>
            {t("section7.title")}
          </Typography>
          <Typography variant="body2" component="span">
            <Markdown>
              {t("section7.content", {
                dpoEmail: DPO_EMAIL,
                micrositeUrl: "/",
                supervisoryUrl: SUPERVISORY_AUTHORITY_URL,
                dmsaFullUrl: "/",
              })}
            </Markdown>
          </Typography>
        </div>
        <Typography variant="body2" component="span">
          <Markdown>
            {t("view", {
              dpoEmail: DPO_EMAIL,
              micrositeUrl: ROUTES.root,
              supervisoryUrl: SUPERVISORY_AUTHORITY_URL,
              dmsaFullUrl: ROUTES.southAfrica,
              dmukFullUrl: ROUTES.unitedKingdom,
            })}
          </Markdown>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
          color="primary"
          data-testid="privacy-uk-close"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyDialogUK;
