/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Hidden from "@mui/material/Hidden";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import logo from "../../DMFullColour.svg";
import { ROUTES } from "../../core/constants";
import RegionDialog from "../../components/RegionDialog";
import { useStyles } from "./component.styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

function Header() {
  const { t } = useTranslation("global");
  const [portalRegionIsOpen, setPortalRegionIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const styles = useStyles();
  return (
    <>
      <header css={styles.header}>
        <Container>
          <div css={styles.headerInner}>
            <Link to={ROUTES.root} component={RouterLink}>
              <img
                src={logo}
                alt="Discover Me"
                css={styles.logo}
                height={36.5}
                width={158.44}
              />
            </Link>

            <nav css={styles.nav}>
              <Hidden mdDown>
                <ul css={styles.navList}>
                  <li>
                    <Link
                      to={ROUTES.unitedKingdom}
                      component={RouterLink}
                      underline="hover"
                      color="inherit"
                    >
                      {t("menu.dmuk")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={ROUTES.southAfrica}
                      component={RouterLink}
                      underline="hover"
                      color="inherit"
                    >
                      {t("menu.dmsa")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={ROUTES.contact}
                      component={RouterLink}
                      underline="hover"
                      color="inherit"
                    >
                      {t("menu.contact")}
                    </Link>
                  </li>
                </ul>
              </Hidden>
            </nav>
            <Hidden mdUp>
              <IconButton onClick={onToggle}>
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              <div css={styles.secondaryNav}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setPortalRegionIsOpen(true);
                  }}
                >
                  {t("menu.portal")}
                </Button>
              </div>
              <div css={styles.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.participants}
                >
                  {t("menu.participate")}
                </Button>
              </div>
            </Hidden>
          </div>
        </Container>
        <RegionDialog
          open={portalRegionIsOpen}
          onClose={() => {
            setPortalRegionIsOpen(false);
          }}
        />
      </header>
      <Hidden mdUp>
        <Drawer open={isMenuOpen} anchor="right" onClose={onToggle}>
          <div css={styles.drawer}>
            <Box p={2} mb={2} textAlign="right">
              <IconButton onClick={onToggle}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box p={2}>
              <Box mb={2}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  component={RouterLink}
                  to={ROUTES.participants}
                >
                  {t("menu.participate")}
                </Button>
              </Box>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                onClick={() => {
                  setPortalRegionIsOpen(true);
                }}
              >
                {t("menu.portal")}
              </Button>
            </Box>
            <Divider />
            <List>
              <ListItem>
                <Link
                  component={RouterLink}
                  onClick={() => {
                    onToggle();
                  }}
                  to={ROUTES.unitedKingdom}
                  underline="hover"
                  color="inherit"
                >
                  {t("menu.dmuk")}
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to={ROUTES.southAfrica}
                  component={RouterLink}
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {t("menu.dmsa")}
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to={ROUTES.contact}
                  component={RouterLink}
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {t("menu.contact")}
                </Link>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Header;
