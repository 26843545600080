/** @jsxImportSource @emotion/react */
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export type UKSearchProps = {
  handleSearch: (keyword: string) => void;
  buttonText: string;
  placeholder: string;
  stacked?: boolean;
};

export type FormikValues = {
  [x: string]: string;
};

function UKSearch({
  handleSearch,
  buttonText,
  placeholder,
  stacked = false,
}: UKSearchProps) {
  const validationSchema = yup.object().shape({
    search: yup.string().required("Enter a search term"),
  });
  const { trackEvent } = useMatomo();
  const formik = useFormik({
    initialValues: { search: "" },
    validationSchema,
    onSubmit: (values: FormikValues) => {
      trackEvent({
        category: "search",
        action: `search-for "${values.search}"`,
      });
      handleSearch(values.search);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={stacked ? 12 : true}>
          <TextField
            id="outlined-basic"
            label={placeholder}
            variant="outlined"
            size="medium"
            fullWidth
            error={formik.errors.search ? true : false}
            name="search"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.search}
          />
        </Grid>
        <Grid
          item
          xs={stacked ? 12 : "auto"}
          alignItems="stretch"
          style={{ display: "flex" }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth={stacked}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default UKSearch;
