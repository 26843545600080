/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";

import Strip from "../../../../components/Strip";
import { ROUTES } from "../../../../core/constants";
import { useStyles } from "./component.styles";

function ParticipateStrip() {
  const styles = useStyles();
  return (
    <Parallax bgImage="/images/StripBg.png">
      <Strip extraCss={styles.strip}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              color="white"
              fontWeight={300}
            >
              Let's help future generations live in good health for longer
            </Typography>
          </Grid>{" "}
          <Grid item xs={12} md={4} textAlign="center">
            <div style={{ color: "white" }}>
              <Hidden mdDown>
                <Button
                  size="large"
                  variant="outlined"
                  color="inherit"
                  to={ROUTES.participants}
                  component={Link}
                >
                  Participate
                </Button>
              </Hidden>
              <Hidden mdUp>
                <Button
                  size="large"
                  variant="outlined"
                  color="inherit"
                  fullWidth
                  to={ROUTES.participants}
                  component={Link}
                >
                  Participate
                </Button>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Strip>
    </Parallax>
  );
}

export default ParticipateStrip;
