/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import AnimationOnScroll from "react-animate-on-scroll";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";
import Box from "@mui/material/Box";

function InvolvementStrip() {
  const { t } = useTranslation("dmsa");
  return (
    <Strip variant="primary" arrowDirection="down">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align="inherit"
              color="secondary"
            >
              {t("involvement.pre")}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="inherit"
              gutterBottom
              color="white"
            >
              {t("faqs.sections.2.1.q")}
            </Typography>
          </Box>
          <Typography variant="body1" component="span" color="white">
            <Markdown>{t("faqs.sections.2.1.a")}</Markdown>
          </Typography>

          <Typography
            variant="h5"
            component="h3"
            align="left"
            color="white"
            gutterBottom
          >
            {t("faqs.sections.2.4.q")}
          </Typography>
          <Typography variant="body1" component="span" color="white">
            <Markdown>{t("faqs.sections.2.4.a")}</Markdown>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Box mb={4}>
              <ResponsiveImage src="/images/takepartsa.jpg" alt="Taking Part" />
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <ResponsiveImage
                  isRounded={false}
                  hasBorder
                  src="/images/kwazulu-natal-province.png"
                  alt="Kwazulu-Natal Province"
                  maxHeight={90}
                />
              </Grid>
              <Grid item xs={6}>
                <ResponsiveImage
                  isRounded={false}
                  src="/images/kwazulu-uni-logo.png"
                  alt="University of Kwazulu-Natal"
                  maxHeight={90}
                />
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default InvolvementStrip;
