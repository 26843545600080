import Fuse from "fuse.js";
import { ODSRow } from "../../store/practices/types";
import { matchSorter } from "match-sorter";
import { getDistance } from "../utils/location.utils";

const odsApi = {
  getByOds: async (odsCode: string): Promise<any> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ODS_CODE_API_URL}/ods-codes/${odsCode}`
      ).then((response: any) => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      });

      if (response.data) {
        return response;
      } else return { errors: ["No practice found"] };
    } catch (error) {
      return { errors: [error] };
    }
  },
  searchPractice: async (searchQuery: string): Promise<any> => {
    try {
      const response = await fetch("/data.json").then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      });

      // gets location from search query
      let geocoded = await fetchGeocode(`${searchQuery}, UK`);
      if (geocoded.Items.length === 0) {
        geocoded = await fetchGeocode(searchQuery);
      }

      let sortedData: ODSRow[];

      // If location able to be found from search term, find physically closest practices
      if (
        geocoded.Items.length > 0 &&
        !geocoded.Items[0].Error &&
        (searchQuery.match(/[0-9]/) ||
          hasFoundLocation(
            searchQuery.split(",")[0],
            geocoded.Items[0].Location
          ))
      ) {
        const { Easting, Northing } = geocoded.Items[0];
        sortedData = response.in.sort((a: ODSRow, b: ODSRow) => {
          return (
            getDistance(Easting, Northing, a.easting!, a.northing!) -
            getDistance(Easting, Northing, b.easting!, b.northing!)
          );
        });
        // otherwise find practices that match search terms
      } else {
        sortedData = stringSearch(searchQuery, response);
      }

      return new Promise((resolve, reject) => {
        resolve({
          data: sortedData,
        });
      });
    } catch (error) {
      return { errors: [error] };
    }
  },
};

const fetchGeocode = (searchQuery: string) => {
  return fetch(
    `https://api.addressy.com/Geocoding/UK/Geocode/v2.10/json3.ws?Key=${encodeURIComponent(
      process.env.REACT_APP_LOQATE_API_KEY!
    )}&Location=${searchQuery}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((response) => {
    if (!response.ok) {
      throw new Error("HTTP error " + response.status);
    }
    return response.json();
  });
};

const hasFoundLocation = (searchQuery: string, match: string) => {
  const options = {
    includeScore: true,
    minMatchCharLength: 4,
    threshold: 0.3,
    distance: 20,
    keys: ["keywords"],
  };
  const fuse = new Fuse([match], options);
  const result = fuse.search(searchQuery);
  return result.length > 0;
};

const stringSearch = (searchQuery: string, response: any) => {
  const options = {
    includeScore: true,
    minMatchCharLength: 2,
    threshold: 0.3,
    distance: 20,
    keys: ["keywords"],
  };

  /* converts fields to keywords so multiword searches have a better chance of matching */
  const allPractices = response.in.map((practice: ODSRow) => ({
    ...practice,
    keywords: [
      practice.name,
      practice.postcode,
      practice.address1,
      practice.address2,
      practice.address3,
      ...(practice.alternativeNames ? practice.alternativeNames : []),
      practice.town,
    ],
  }));

  /* searches for mathematically probable results */
  const fuse = new Fuse(allPractices, options);
  const data = fuse
    .search(searchQuery)
    .map((row) => {
      return row.item as ODSRow;
    })
    .filter((row: ODSRow) => {
      const roleID = row.nonPrimaryRoleIds;
      return roleID !== "RO279";
    });

  /* sorts results like a human */
  return matchSorter(data, searchQuery, {
    keys: [
      "name",
      "postcode",
      {
        maxRanking: matchSorter.rankings.CONTAINS,
        key: "address1",
      },
      {
        maxRanking: matchSorter.rankings.CONTAINS,
        key: "address2",
      },
      {
        maxRanking: matchSorter.rankings.CONTAINS,
        key: "address3",
      },
      { maxRanking: matchSorter.rankings.CONTAINS, key: "town" },
    ],
    threshold: matchSorter.rankings.NO_MATCH,
  });
};

export default odsApi;
