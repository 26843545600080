/** @jsxImportSource @emotion/react */
import { useState } from "react";

import MainLayout from "../../layouts/Main";
import AimsHome from "./components/AimsHome";
import BenefitsHome from "./components/BenefitsHome";
import GeneticsHome from "./components/GeneticsHome";
import HeroHome from "./components/HeroHome";
import SouthAfricaHome from "./components/SouthAfricaHome";
import ParticipateStrip from "./components/ParticipateStrip";
import VideoDialog from "../../components/VideoDialog";

function HomeScene() {
  const [isVideoOpen, setisVideoOpen] = useState(false);

  return (
    <MainLayout>
      <HeroHome
        onOpenVideo={() => {
          setisVideoOpen(true);
        }}
      />

      <AimsHome />
      <GeneticsHome />
      <SouthAfricaHome />
      <BenefitsHome />
      <ParticipateStrip />
      <VideoDialog
        open={isVideoOpen}
        onClose={() => {
          setisVideoOpen(false);
        }}
      />
    </MainLayout>
  );
}

export default HomeScene;
