import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export enum Language {
  ZU = "zu",
  EN = "en",
}

export const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageSelect = (lang: string) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === Language.ZU) {
      i18n.changeLanguage(Language.ZU);
    }
  }, [i18n]);

  return (
    <>
      <span className="App-nav-lang">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
        >
          {i18n.language === Language.EN && <>EN</>}
          {i18n.language === Language.ZU && <>ZU</>}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleLanguageSelect(Language.EN);
            }}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleLanguageSelect(Language.ZU);
            }}
          >
            Zulu
          </MenuItem>
        </Menu>
      </span>
    </>
  );
};

export default LanguageSelect;
