import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ExternalLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import Strip from "../../components/Strip";
import { CONTACT_ADDRESS, ROUTES } from "../../core/constants";
import MainLayout from "../../layouts/Main";
import logoUK from "../../DMFullColourUK.svg";
import logoSA from "../../DMFullColourSA.svg";
import { Fragment } from "react";

function ContactScene() {
  return (
    <MainLayout>
      <Strip>
        <Box mb={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={10}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                align="center"
                color="primary"
              >
                <span style={{ fontWeight: 100 }}>Contact</span>{" "}
                <strong>us</strong>
              </Typography>
              <Typography
                variant="body1"
                component="p"
                color="inherit"
                textAlign="center"
              >
                Discover Me is currently available in the following regions:
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md textAlign="center">
            <Paper variant="outlined">
              <Box p={8}>
                <Box mb={8} textAlign="left">
                  <Link to={ROUTES.unitedKingdom}>
                    <img src={logoUK} alt="Discover Me UK" width={250} />
                  </Link>
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  color="inherit"
                  textAlign="left"
                  paragraph
                >
                  FAO Discover Me UK,
                  <br />
                  Omnigen Biodata Ltd
                  {CONTACT_ADDRESS.split(",").map((line) => (
                    <Fragment key={`address-line-${line}`}>
                      ,
                      <br />
                      {line}
                    </Fragment>
                  ))}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  color="inherit"
                  textAlign="left"
                >
                  <strong>Telephone: </strong>
                  <ExternalLink href="tel:+4420 3866 8941">
                    020 3866 8941
                  </ExternalLink>
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="inherit"
                  paragraph
                  textAlign="left"
                >
                  (standard rates from your carrier will apply)
                </Typography>

                <Typography
                  variant="body1"
                  component="p"
                  color="inherit"
                  textAlign="left"
                >
                  <strong>Email: </strong>
                  <ExternalLink href="mailto:uk@discovermestudy.com">
                    uk@discovermestudy.com
                  </ExternalLink>
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md textAlign="center">
            <Paper variant="outlined">
              <Box p={8}>
                <Box mb={8} textAlign="left">
                  <Link to={ROUTES.southAfrica}>
                    <img src={logoSA} alt="Discover Me SA" width={250} />
                  </Link>
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  color="inherit"
                  textAlign="left"
                  paragraph
                >
                  FAO Discover Me SA,
                  <br />
                  Omnigen Biodata Ltd
                  {CONTACT_ADDRESS.split(",").map((line) => (
                    <Fragment key={`address-line-${line}`}>
                      ,
                      <br />
                      {line}
                    </Fragment>
                  ))}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  color="inherit"
                  paragraph
                  textAlign="left"
                >
                  <strong>Telephone: </strong>
                  <ExternalLink href="tel:+27834633156">
                    +27 (0) 83 4633 156
                  </ExternalLink>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  color="inherit"
                  textAlign="left"
                >
                  <strong>Email: </strong>
                  <ExternalLink href="mailto:discovermesa@ukzn.ac.za">
                    discovermesa@ukzn.ac.za
                  </ExternalLink>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Strip>
    </MainLayout>
  );
}

export default ContactScene;
