/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import AnimationOnScroll from "react-animate-on-scroll";
import Markdown from "react-markdown";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";
import Box from "@mui/material/Box";

function InvolvementStrip() {
  const { t } = useTranslation("dmsa");
  return (
    <Strip variant="neutral">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <ResponsiveImage src="/images/fitsa.jpg" alt="healthcare" />
          </AnimationOnScroll>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align="inherit"
              color="secondary.contrastText"
            >
              {t("involvement.pre")}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="inherit"
              gutterBottom
            >
              {t("faqs.sections.2.2.q")}
            </Typography>
          </Box>
          <Typography variant="body1" component="span">
            <Markdown>{t("faqs.sections.2.2.a")}</Markdown>
          </Typography>

          <Typography variant="h5" component="h3" align="left" gutterBottom>
            {t("faqs.sections.2.3.q")}
          </Typography>
          <Typography variant="body1" component="span">
            <Markdown>{t("faqs.sections.2.3.a")}</Markdown>
          </Typography>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default InvolvementStrip;
