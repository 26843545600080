/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

function TermsDialog({ onClose, isOpen }: any) {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    if (isOpen) {
      trackPageView({
        href: "terms-notice",
      });
    }
  }, [isOpen, trackPageView]);
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="md">
      <DialogTitle>
        <Typography variant="body1">Omnigen Biodata Ltd</Typography>
        <Typography variant="h5">
          Expression of Interest: Terms and Conditions
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="error" paragraph>
          PLEASE READ THESE TERMS CAREFULLY
        </Typography>
        <Typography variant="h5" paragraph>
          1. WHO WE ARE AND WHAT THIS AGREEMENT DOES
        </Typography>
        <Typography variant="body2" paragraph>
          These terms and conditions are applicable when you register your
          expression of interest with Omnigen Biodata Ltd of Four Station
          Square, Cambridge, CB1 2GE in relation to the Discover Me study (the{" "}
          <strong>"Study"</strong>).{" "}
        </Typography>
        <Typography variant="h5" paragraph>
          2. YOUR PRIVACY
        </Typography>
        <Typography variant="body2" paragraph>
          Under data protection legislation, we are required to provide you with
          certain information about who we are, how we process your personal
          data and for what purposes, and your rights in relation to your
          personal data and how to exercise them.{" "}
        </Typography>
        <Typography variant="h6" paragraph>
          2.1 THE DATA WE COLLECT ABOUT YOU
        </Typography>
        <Typography variant="body2" paragraph>
          When you register your expression of interest with us, we may collect,
          use, store and transfer different kinds of personal data about you as
          follows:
        </Typography>
        <Typography variant="body2" paragraph component="ul">
          <li>Identity Data: First name and last name</li>
          <li>Contact Data: Contact number/e-mail ID</li>
          <li>Profile Data: Date of birth and GP Practice</li>
          <li>
            Marketing and Communications Data: User preferences when you opt in
          </li>
          <li>
            To record your preferences to contact you for participation in any
            future studies if you opt-in.{" "}
            <strong>
              If you wish to opt-out and do not want to receive further
              correspondence from us in the future, you can do so by contacting
              us at{" "}
              <a href="mailto: uk@discovermestudy.com">
                uk@discovermestudy.com
              </a>
            </strong>
          </li>
        </Typography>
        <Typography variant="h6" paragraph>
          2.2. HOW WE SHARE YOUR PERSONAL DATA
        </Typography>
        <Typography variant="body2" paragraph>
          We may share your personal data with external third parties.{" "}
        </Typography>
        <Typography variant="h5" paragraph>
          3. INTERNATIONAL TRANSFERS
        </Typography>
        <Typography variant="body2" paragraph>
          We may transfer, store and process your personal data outside the UK.
          Whenever we transfer your personal information outside of the UK and
          the EU, we ensure it receives additional protection as required by
          law. You can contact us at{" "}
          <a href="mailto: dpo@omnigenbiodata.co.uk">
            dpo@omnigenbiodata.co.uk
          </a>{" "}
          for more detail on this. Please be aware that internet transmissions
          are never completely private or secure and that any information you
          give us using the expression of interest form may be read or
          intercepted by others, even if there is a special notice that a
          particular transmission is encrypted.
        </Typography>
        <Typography variant="body2" paragraph>
          By expressing your interest in the Study, we may also let you know
          about other future research which we undertake or conduct.
        </Typography>
        <Typography variant="h5" paragraph>
          4. COMMUNICATING WITH YOU
        </Typography>
        <Typography variant="body2" paragraph>
          From time to time, we may contact you via email or telephone using the
          personal data we collect and maintain about you, as set out above.{" "}
        </Typography>
        <Typography variant="body2" paragraph>
          We may contact you for a number of reasons, for example (but not
          limited to):
        </Typography>
        <Typography variant="body2" paragraph component="ul">
          <li>
            Informing you that you are now able to take part in the Study or
            inviting you to take part in the Study, following your expression of
            interest. We do not give any assurance that an expression of
            interest will guarantee your participation in the Study, and we may
            withdraw your expression of interest at any time, without giving a
            reason.
          </li>

          <li>
            Contacting you (without limitation) so as to notify you regarding
            any important updates relating to your expression of interest, and
            for our own internal administrative purposes.
          </li>

          <li>
            We may use your information to contact you to provide information
            about any studies we may conduct in the future.{" "}
          </li>

          <li>
            You may receive marketing communications from us. We may use your
            identity, contact details and expression of interest information to
            form a view on what we think you may want or need, or what may be of
            interest to you. This is how we decide which products, services and
            offers may be relevant for you (i.e. 'marketing' or 'market
            research').
          </li>
        </Typography>
        <Typography variant="body2" paragraph>
          You are not obliged to respond to these communications, and you can
          unsubscribe from communications at any time. If you choose to
          unsubscribe, we will delete all the information held about you, except
          for some basic details which we might hold on you such as your name
          and e-mail ID, which will be retained and used exclusively under our
          Do Not Contact list.{" "}
        </Typography>
        <Typography variant="h5" paragraph>
          5. DISCLAIMER
        </Typography>
        <Typography variant="body2" paragraph>
          You must be 18 or over to accept these terms and express your interest
          in the Study.
        </Typography>
        <Typography variant="body2" paragraph>
          If you are concerned that your details may have been compromised,
          please contact us immediately by emailing us at{" "}
          <a href="mailto: uk@discovermestudy.com">uk@discovermestudy.com</a> or
          call us on <a href="tel:+4420 3866 8941">020 3866 8941</a>.
        </Typography>
        <Typography variant="h5" paragraph>
          6. YOUR LEGAL RIGHTS
        </Typography>
        <Typography variant="body2" paragraph>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data including the right to receive
          a copy of the personal data we hold about you and the right to make a
          complaint at any time to the Information Commissioner's Office, the UK
          regulator for data protection issues (
          <a href="http://www.ico.org.uk">www.ico.org.uk</a>).
        </Typography>
        <Typography variant="h5" paragraph>
          7. ACCEPTABLE USE RESTRICTIONS
        </Typography>
        <Typography variant="body2" paragraph>
          You must:
        </Typography>
        <Typography variant="body2" paragraph component="ul">
          <li>
            not use any of our resources in connection with the expression of
            interest form in any unlawful manner, for any unlawful purpose, or
            in any manner inconsistent with these terms or the rights of third
            parties, or act fraudulently or maliciously, for example, by hacking
            into or inserting malicious code, such as viruses, or harmful data,
            into the expression of interest form, or any operating system;
          </li>
          <li>
            not misuse our site by knowingly introducing viruses, trojans,
            worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our site, the server on which our site is stored, or any
            server, computer or database connected to our site. You must not
            attack our site via a denial-of-service attack or a distributed
            denial-of service attack. By breaching this provision, you would
            commit a criminal offence under the Computer Misuse Act 1990. We
            will report any such breach to the relevant law enforcement
            authorities, and we will co-operate with those authorities by
            disclosing your identity to them. In the event of such a breach,
            your right to use our site will cease immediately.
          </li>
        </Typography>
        <Typography variant="h5" paragraph>
          8. WE MAY TRANSFER THIS AGREEMENT TO SOMEONE ELSE
        </Typography>
        <Typography variant="body2" paragraph>
          We may transfer our rights and obligations under these terms to
          another organisation. We will always tell you in writing if this
          happens and we will ensure that the transfer will not affect your
          rights under the contract.
        </Typography>
        <Typography variant="h5" paragraph>
          9. NO RIGHTS FOR THIRD PARTIES
        </Typography>
        <Typography variant="body2" paragraph>
          This agreement does not give rise to any rights under the Contracts
          (Rights of Third Parties) Act 1999 to enforce any term of this
          agreement.
        </Typography>
        <Typography variant="h5" paragraph>
          10. IF A COURT FINDS PART OF THIS CONTRACT ILLEGAL, THE REST WILL
          CONTINUE IN FORCE
        </Typography>
        <Typography variant="body2" paragraph>
          Each of the paragraphs of these terms operates separately. If any
          court or relevant authority decides that any of them are unlawful, the
          remaining paragraphs will remain in full force and effect.
        </Typography>
        <Typography variant="h5" paragraph>
          11. EVEN IF WE DELAY IN ENFORCING THIS CONTRACT, WE CAN STILL ENFORCE
          IT LATER
        </Typography>
        <Typography variant="body2" paragraph>
          Even if we delay in enforcing this contract, we can still enforce it
          later. If we do not insist immediately that you do anything you are
          required to do under these terms, or if we delay in taking steps
          against you in respect of your breaking this contract, that will not
          mean that you do not have to do those things and it will not prevent
          us taking steps against you at a later date.
        </Typography>
        <Typography variant="h5" paragraph>
          12. WHICH LAWS APPLY TO THIS CONTRACT AND WHERE YOU MAY BRING LEGAL
          PROCEEDINGS
        </Typography>
        <Typography variant="body2" paragraph>
          The laws of England and Wales apply to these terms and you can bring
          legal proceedings in respect of the products in the English courts. If
          you live in Scotland, you can bring legal proceedings in respect of
          the products in either the Scottish or the English courts. If you live
          in Northern Ireland, you can bring legal proceedings in respect of the
          products in either the Northern Irish or the English courts.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
          color="primary"
          data-testid="terms-close"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TermsDialog;
