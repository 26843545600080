/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

function RegisteredError() {
  const { t } = useTranslation("dmuk");
  const { trackEvent } = useMatomo();
  useEffect(() => {
    trackEvent({ category: "registration", action: "already-exists-error" });
  }, [trackEvent]);
  return (
    <Box mb={4}>
      <Alert severity="error">
        <strong>{t("regform.messages.exists.title")}</strong>
        <br />
        {t("regform.messages.exists.para1")}
      </Alert>
    </Box>
  );
}

export default RegisteredError;
