import { useEffect, useState } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import ReactMarkdown from "react-markdown";
import { scroller } from "react-scroll";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";

export interface FAQEntry {
  q: string;
  a: string;
}

export interface FAQSection {
  title: string;
  questions: FAQEntry[];
}

export interface FAQBlockProps {
  sections: FAQSection[];
  section?: string | null;
  question?: string | null;
}

export const SCROLLER_OPTIONS = {
  duration: 800,
  delay: 0,
  smooth: "easeInOutQuart",
  offset: -105,
};

export const FAQBlock = ({
  sections,
  section = null,
  question = null,
}: FAQBlockProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedSection, setExpandedSection] = useState<string | null>(
    section
  );
  const [expandedQuestion, setExpandedQuestion] = useState<string | null>(
    question
  );
  const { trackEvent } = useMatomo();
  useEffect(() => {
    if (section) {
      scroller.scrollTo(`faq-section-${section}-header`, SCROLLER_OPTIONS);
    }
  }, [section]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (searchParams) {
        setSearchParams({});
      }
      if (isExpanded) {
        const section = sections[Number(expandedSection)].title;
        trackEvent({ category: "FAQ", action: `expand-section "${section}"` });
      }
      setExpandedSection(isExpanded ? panel : null);
    };

  const handleChangeQuestion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (searchParams) {
        setSearchParams({});
      }
      if (isExpanded) {
        const question =
          sections[Number(expandedSection)].questions[Number(panel)].q;
        trackEvent({
          category: "FAQ",
          action: `expand-question "${question}"`,
        });
      }
      setExpandedQuestion(isExpanded ? panel : null);
    };

  return (
    <>
      {sections &&
        sections.map((section: FAQSection, sectionIndex: number) => (
          <Accordion
            expanded={expandedSection === sectionIndex.toString()}
            onChange={handleChange(`${sectionIndex}`)}
            key={`panel-${sectionIndex}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-section-${sectionIndex}-content`}
              id={`faq-section-${sectionIndex}-header`}
            >
              <Typography
                variant="body1"
                component="p"
                fontWeight={700}
                color="primary"
              >
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-section-${sectionIndex}-content`}>
              {section.questions.map(
                (questions: any, questionIndex: number) => (
                  <Box mb={1} key={`panel-${sectionIndex}-${questionIndex}`}>
                    <Accordion
                      expanded={
                        expandedSection === sectionIndex.toString() &&
                        expandedQuestion === questionIndex.toString()
                      }
                      onChange={handleChangeQuestion(`${questionIndex}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`faq-question-${questionIndex}-content`}
                        id={`faq-question-${questionIndex}-header`}
                      >
                        <Typography>
                          <HelpOutlineIcon
                            style={{ verticalAlign: "middle", marginRight: 10 }}
                          />
                          {questions.q}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        id={`faq-question-${questionIndex}-content`}
                      >
                        <ReactMarkdown>{questions.a}</ReactMarkdown>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default FAQBlock;
