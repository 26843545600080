import { createContext } from "react";

export interface MainLayoutContextType {
  isPrivacyOpen: boolean;
  isTermsOpen: boolean;
  handleCloseTerms: () => void;
  handleOpenTerms: () => void;
  handleClosePrivacy: () => void;
  handleOpenPrivacy: () => void;
}

const defaultMainLayoutConext: MainLayoutContextType = {
  isPrivacyOpen: false,
  isTermsOpen: false,
  handleCloseTerms: () => undefined,
  handleOpenTerms: () => undefined,
  handleClosePrivacy: () => undefined,
  handleOpenPrivacy: () => undefined,
};

const MainLayoutContext = createContext(defaultMainLayoutConext);

export default MainLayoutContext;
