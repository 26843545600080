/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";

import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";
import { ROUTES } from "../../../../core/constants";
import AnimationOnScroll from "react-animate-on-scroll";

function BenefitsHome() {
  return (
    <Strip>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              align="inherit"
              color="secondary.contrastText"
            >
              PARTICIPATION
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="inherit"
              gutterBottom
              color="primary"
            >
              How will your participation in <br />
              Discover Me make a difference?
            </Typography>
          </Box>
          <Box mb={4}>
            <Box p={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <ResponsiveImage
                      src="/images/research.jpg"
                      alt="research"
                    />
                  </AnimationOnScroll>
                </Grid>
                <Grid item xs={12} md>
                  <Typography
                    variant="h5"
                    component="h5"
                    align="left"
                    paragraph
                  >
                    Medical Research
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="left"
                    paragraph
                  >
                    Studying health and genetic information together may allow
                    us to find new treatments or improve existing treatments for
                    conditions such as cancer and diabetes, and improve the
                    health of future generations.
                  </Typography>
                  <Hidden mdDown>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      component={Link}
                      to={ROUTES.participants}
                    >
                      Discover More{" "}
                      <KeyboardDoubleArrowRightIcon color="inherit" />
                    </Button>
                  </Hidden>
                  <Hidden mdUp>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      fullWidth
                      component={Link}
                      to={ROUTES.participants}
                    >
                      Discover More{" "}
                      <KeyboardDoubleArrowRightIcon color="inherit" />
                    </Button>
                  </Hidden>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box>
            <Box p={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <ResponsiveImage
                      src="/images/healthcare2.jpg"
                      alt="healthcare2"
                    />
                  </AnimationOnScroll>
                </Grid>
                <Grid item xs={12} md>
                  <Typography
                    variant="h5"
                    component="h5"
                    align="left"
                    paragraph
                  >
                    Personal Health Insights
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    align="left"
                    paragraph
                  >
                    As part of this study, you will be able to access some
                    information from your health and care records. This may
                    include information that you might find interesting or
                    useful for managing your health, such as blood pressure and
                    body mass index (BMI), as well as medications you have
                    taken, and vaccinations you have had.
                  </Typography>
                  <Hidden mdDown>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      component={Link}
                      to={ROUTES.participants}
                    >
                      Discover More{" "}
                      <KeyboardDoubleArrowRightIcon color="inherit" />
                    </Button>
                  </Hidden>
                  <Hidden mdUp>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      fullWidth
                      component={Link}
                      to={ROUTES.participants}
                    >
                      Discover More{" "}
                      <KeyboardDoubleArrowRightIcon color="inherit" />
                    </Button>
                  </Hidden>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default BenefitsHome;
