import MainLayout from "../../layouts/Main";
import FAQStrip from "./components/FAQStrip";
import GeneticDataStrip from "./components/GeneticDataStrip";
import HeroSA from "./components/HeroSA";
import InvolvementStrip from "./components/InvolvementStrip";
import PostEnrolmentStrip from "./components/PostEnrolmentStrip";
import PrivacyStrip from "./components/PrivacyStrip";
import TakingPartStrip from "./components/TakingPartStrip";

function SouthAfricaScene() {
  return (
    <MainLayout>
      <HeroSA />
      <TakingPartStrip />
      <InvolvementStrip />
      <PostEnrolmentStrip />
      <GeneticDataStrip />
      <FAQStrip />
      <PrivacyStrip />
    </MainLayout>
  );
}

export default SouthAfricaScene;
