import { css } from "@emotion/react";

export const useStyles = () => {
  return {
    isResponsive: css`
      max-width: 100%;
      width: 100%;
    `,
    isRounded: css`
      border-radius: 3rem 0.2rem 3rem 0.2rem;
    `,
    isRaised: css`
      box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.3);
    `,
    hasBorder: css`
      border: 5px solid white;
    `,
  };
};
