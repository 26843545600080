import { Routes, Route } from "react-router-dom";

import { ROUTES } from "../../core/constants";
import MainScene from "./scenes/Main";
import ResultScene from "./scenes/Result";
import ResultsScene from "./scenes/Results";

function UnitedKingdomScene() {
  return (
    <Routes>
      <Route path={ROUTES.unitedKingdomResults} element={<ResultsScene />} />
      <Route path={ROUTES.unitedKingdomResult} element={<ResultScene />} />
      <Route path="*" element={<MainScene />} />
    </Routes>
  );
}

export default UnitedKingdomScene;
