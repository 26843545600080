/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import AnimationOnScroll from "react-animate-on-scroll";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";
function GeneticDataStrip() {
  const { t } = useTranslation("dmsa");
  return (
    <Strip variant="neutral">
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Hidden mdDown>
            <div style={{ position: "relative" }}>
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <ResponsiveImage
                  src="/images/mapsa.png"
                  alt="UK Map"
                  isRaised={false}
                />
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                delay={300}
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <img
                  src="/images/stats2.png"
                  alt="stats example 1"
                  width={230}
                />
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                delay={400}
                style={{ position: "absolute", bottom: 0, left: 0 }}
              >
                <img
                  src="/images/stats1.png"
                  alt="stats example 2"
                  width={230}
                />
              </AnimationOnScroll>
            </div>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box textAlign={{ xs: "center", sm: "right" }}>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align="inherit"
              color="secondary.contrastText"
            >
              {t("genetics.pre")}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="left"
              gutterBottom
              color="primary"
              textAlign="inherit"
            >
              {t("faqs.sections.2.6.q")}
            </Typography>
            <Typography variant="body1" component="span" textAlign="inherit">
              <Markdown>{t("faqs.sections.2.6.a")}</Markdown>
            </Typography>
            <Typography
              variant="h5"
              component="h3"
              align="left"
              gutterBottom
              color="primary"
              textAlign="inherit"
            >
              {t("faqs.sections.2.7.q")}
            </Typography>
            <Typography variant="body1" component="span" textAlign="inherit">
              <Markdown>{t("faqs.sections.2.7.a")}</Markdown>
            </Typography>
            <Typography
              variant="h5"
              component="h3"
              align="left"
              gutterBottom
              color="primary"
              textAlign="inherit"
            >
              {t("faqs.sections.2.8.q")}
            </Typography>
            <Typography variant="body1" component="span" textAlign="inherit">
              <Markdown>{t("faqs.sections.2.8.a")}</Markdown>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default GeneticDataStrip;
