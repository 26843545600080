/** @jsxImportSource @emotion/react */
import { useStyles } from "./component.styles";

export type ResponsiveImageProps = {
  alt?: string;
  isResponsive?: boolean;
  isRounded?: boolean;
  isRaised?: boolean;
  src: string;
  hasBorder?: boolean;
  maxHeight?: number;
};

const ResponsiveImage = ({
  isResponsive = true,
  alt,
  isRounded = true,
  isRaised = true,
  src,
  hasBorder = false,
  maxHeight,
  ...other
}: ResponsiveImageProps) => {
  const styles = useStyles();
  return (
    <img
      src={src}
      alt={alt}
      {...other}
      css={[
        isResponsive ? styles.isResponsive : undefined,
        isRounded ? styles.isRounded : undefined,
        isRaised ? styles.isRaised : undefined,
        hasBorder ? styles.hasBorder : undefined,
      ]}
      style={{ maxHeight: maxHeight ? maxHeight : undefined }}
    />
  );
};

export default ResponsiveImage;
