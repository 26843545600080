export function getDistance(
  east1: number,
  north1: number,
  east2: number,
  north2: number
) {
  const a = east2 - east1;
  const b = north2 - north1;
  const c = a ** 2 + b ** 2;
  return Math.sqrt(c);
}
