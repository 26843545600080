import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { Video } from "../Video";

export interface VideoDialogProps {
  onClose: () => void;
  open?: boolean;
}

export const VideoDialog = ({ onClose, open = false }: VideoDialogProps) => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    if (open) {
      trackPageView({
        href: "dm-video",
      });
    }
  }, [open, trackPageView]);
  const handleClose = () => {
    onClose();
  };
  const videoUK = "https://player.vimeo.com/video/761790224";
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth>
      <Box p={2}>
        <Video video={videoUK} />
      </Box>
    </Dialog>
  );
};

export default VideoDialog;
