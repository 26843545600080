/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";

import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";
import { ROUTES } from "../../../../core/constants";
import { useStyles } from "./SouthAfricaHome.styles";
import AnimationOnScroll from "react-animate-on-scroll";

function SouthAfricaHome() {
  const styles = useStyles();
  const isFullWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  return (
    <Strip variant="neutralLight" extraCss={styles.neutralSection}>
      <Box mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <ResponsiveImage src="/images/fituk.jpg" alt="United Kingdom" />
            </AnimationOnScroll>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
              <Typography
                variant="body1"
                component="p"
                align="inherit"
                color="secondary.contrastText"
              >
                UNITED KINGDOM
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                align="inherit"
                gutterBottom
                color="primary"
              >
                Discover Me United Kingdom
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography
                variant="body1"
                component="p"
                paragraph
                color="inherit"
                fontWeight={700}
              >
                Discover Me is a nationwide research study looking at how health
                and genetic information can allow us to better understand
                disease.
              </Typography>
              <Typography variant="body1" component="p" color="inherit">
                By collecting this information from thousands of people,
                Discover Me aims to help us find out why diseases may affect
                some people more than others. This could help transform
                healthcare.
              </Typography>
            </Box>
            <div>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                component={Link}
                fullWidth={isFullWidth}
                to={ROUTES.unitedKingdom}
              >
                Discover More <KeyboardDoubleArrowRightIcon color="inherit" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <ResponsiveImage src="/images/southafrica.jpg" alt="southafrica" />
          </AnimationOnScroll>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              align="inherit"
              color="secondary.contrastText"
            >
              INTERNATIONAL
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="inherit"
              gutterBottom
              color="primary"
            >
              Discover Me South Africa
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography
              variant="body1"
              component="p"
              paragraph
              color="inherit"
              fontWeight={700}
            >
              Omnigen is committed to building a discovery science program that
              is globally representative—enabling discovery, supporting
              communities, and building research capacity.
            </Typography>
            <Typography variant="body1" component="p" color="inherit">
              In collaboration with the University of KwaZulu-Natal and Inkosi
              Albert Luthuli Central Hospital, the Discover Me South Africa
              study aims to recruit up to 100,000 individuals, and integrate
              their electronic health record and genetic data to help understand
              disease risk and progression, and improve patient care and
              management. All participants will receive access to their health
              data.
            </Typography>
          </Box>
          <div>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              component={Link}
              fullWidth={isFullWidth}
              to={ROUTES.southAfrica}
            >
              Discover More <KeyboardDoubleArrowRightIcon color="inherit" />
            </Button>
          </div>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default SouthAfricaHome;
