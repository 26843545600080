/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import AnimationOnScroll from "react-animate-on-scroll";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material";
import logoUK from "../../../../../../DMFullColourUK.svg";
import { useStyles } from "./component.styles";
import UKSearch from "../../../../components/UKSearch";
import useMediaQuery from "@mui/material/useMediaQuery";

export type FormikValues = {
  [x: string]: string;
};

export type HeroUKProps = {
  handleSearch: (keyword: string) => void;
};

function HeroUK({ handleSearch }: HeroUKProps) {
  const styles = useStyles();
  const { t } = useTranslation("dmuk");
  const isFullWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  return (
    <section css={styles.hero}>
      <div css={styles.heroTextWrap}>
        <Container>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
                  <img src={logoUK} alt={t("title")} width={350} />
                </Box>
                <Box textAlign={{ xs: "center", sm: "left" }}>
                  <Typography
                    variant="h6"
                    component="span"
                    gutterBottom
                    color="primary"
                    textAlign="inherit"
                  >
                    <ReactMarkdown>{t("summary")}</ReactMarkdown>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    textAlign="inherit"
                    color="primary"
                  >
                    <ReactMarkdown>{t("subtitle")}</ReactMarkdown>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    textAlign="inherit"
                  >
                    <ReactMarkdown>{t("faqs.sections.2.1.a")}</ReactMarkdown>
                  </Typography>
                </Box>
                <Paper>
                  <Box p={2}>
                    <Box mb={1}>
                      <Typography
                        variant="h6"
                        component="h2"
                        gutterBottom
                        color="primary"
                      >
                        {t("search.title")}
                      </Typography>
                    </Box>
                    <UKSearch
                      stacked={isFullWidth}
                      handleSearch={handleSearch}
                      placeholder={t("search.placeholder")}
                      buttonText={t("search.button")}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Container>
      </div>
    </section>
  );
}

export default HeroUK;
