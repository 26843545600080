import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/react";

export const useStyles = (
  variant: "primary" | "light" | "neutral" | "neutralLight" | undefined
) => {
  const theme = useTheme();
  return {
    section: css`
      ${variant === "primary" &&
      `
        background-color: ${theme.palette.primary.main};
      `}
      ${variant === "neutral" &&
      `
        background-color: ${theme.palette.grey[200]};
      `}
      ${variant === "neutralLight" &&
      `
        background-color: ${theme.palette.grey[100]};
      `}
      ${!variant &&
      `
        background-color: ${theme.palette.common.white};
      `}
      z-index: 100;
      position: relative;
      padding: 5rem 0;
      ${theme.breakpoints.only("xs")} {
        padding: 2rem 0;
      }
    `,
    arrow: css`
      ${variant === "primary" &&
      `
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
      `}
      ${variant === "neutral" &&
      `
        background-color: ${theme.palette.grey[200]};
        color: ${theme.palette.text.primary};
      `}
      ${!variant &&
      `
        background-color: ${theme.palette.common.white};
        color: ${theme.palette.text.primary};
      `}
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      position: absolute;
      left: 50%;
      margin-left: -50px;
      border-radius: 50px;
      z-index: -1;
    `,
    arrowDown: css`
      top: -50px;
    `,
    arrowUp: css`
      bottom: -50px;
    `,
  };
};
