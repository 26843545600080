import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "animate.css/animate.min.css";
import theme from "./theme";
import store from "./store";
import { setupI18next } from "./core/utils/i18next.utils";
import "animate.css/animate.min.css";
import { Language } from "./scenes/SouthAfrica/components/LanguageSelect";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <I18nextProvider
      i18n={setupI18next(localStorage.getItem("lang") || Language.EN)}
    >
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
