/** @jsxImportSource @emotion/react */
import ReactMarkdown from "react-markdown";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { MdFamilyRestroom, MdOutlineHealthAndSafety } from "react-icons/md";
import { TbDna } from "react-icons/tb";
import { Hidden } from "@mui/material";
import Box from "@mui/material/Box";
import Strip from "../../../../../../components/Strip";
import ResponsiveImage from "../../../../../../components/ResponsiveImage";
import AnimationOnScroll from "react-animate-on-scroll";

function TakePartStrip() {
  const { t } = useTranslation("dmuk");

  return (
    <Strip variant="primary" arrowDirection="down">
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align="inherit"
              color="secondary"
            >
              {t("takingpart.pre")}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="inherit"
              gutterBottom
              color="white"
            >
              {t("takingpart.title")}
            </Typography>
          </Box>
          <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="span"
              textAlign="inherit"
              color="white"
            >
              <ReactMarkdown>{t("takingpart.summary")}</ReactMarkdown>
            </Typography>
          </Box>
          <Box color="white">
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Box textAlign="center">
                  <MdFamilyRestroom size={60} />
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  textAlign="center"
                  color="white"
                >
                  Ancestry Estimation
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box textAlign="center">
                  <MdOutlineHealthAndSafety size={60} />
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  textAlign="center"
                  color="white"
                >
                  Health Insights
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box textAlign="center">
                  <TbDna size={60} />
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  textAlign="center"
                  color="white"
                >
                  Scientific Discovery
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Hidden mdDown>
            <div style={{ position: "relative" }}>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <ResponsiveImage
                  src="/images/gp2.jpg"
                  alt="UK GP"
                  isRaised={false}
                />
              </AnimationOnScroll>
            </div>
          </Hidden>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default TakePartStrip;
