import { createAsyncThunk } from "@reduxjs/toolkit";
import odsApi from "../../core/api/ods.api";
import { PromiseRejection } from "../../core/types/common.types";
import { ODSRow, SearchPracticeResponse } from "./types";

export const searchPracticeThunk = createAsyncThunk(
  "practices/searchPractice",
  async (keyword: string, { rejectWithValue }: PromiseRejection) => {
    const response: SearchPracticeResponse = await odsApi.searchPractice(
      keyword
    );
    if (response.data) {
      return response.data;
    }
    return rejectWithValue(response.errors);
  }
);

export const getByOdsThunk = createAsyncThunk(
  "practices/getByOds",
  async (odsCode: string, { rejectWithValue }: PromiseRejection) => {
    const response: { data: ODSRow; errors: any } = await odsApi.getByOds(
      odsCode
    );
    if (response.data) {
      return response.data;
    }
    return rejectWithValue(response.errors);
  }
);
