/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import AnimationOnScroll from "react-animate-on-scroll";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import logoUK from "../../../../../../DMFullColourUK.svg";
import { ODSRow } from "../../../../../../store/practices/types";
import ResponsiveImage from "../../../../../../components/ResponsiveImage";
import { DMUK_TERMS_VERSION } from "../../../../../../core/constants";
import RegistrationComplete from "../RegistrationComplete";
import RegistrationError from "../RegistrationError";
import RegistrationForm from "../RegistrationForm";
import RegisteredError from "../RegisteredError";
import { useStyles } from "./component.styles";
import EnrolmentStart from "../EnrolmentStart";

export type HeroResultUKProps = {
  practice?: ODSRow | null;
  isBusy?: boolean;
  hasError?: boolean;
  isComplete?: boolean;
  error?: string | number;
  errorResponse?: any;
  handleSubmit: (options: { [x: string]: string }) => void;
  values?: { [x: string]: string | number | boolean | any[] } | null;
};

function HeroResultUK({
  practice,
  isBusy = false,
  isComplete = false,
  hasError = false,
  error,
  errorResponse,
  handleSubmit,
  values,
}: HeroResultUKProps) {
  const styles = useStyles();
  const { t } = useTranslation("dmuk");

  return (
    <section css={styles.hero}>
      <div css={styles.heroTextWrap}>
        <Container>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box mb={4}>
                  <img src={logoUK} alt={t("title")} width={350} />
                </Box>
                <Hidden mdDown>
                  <Box mb={4}>
                    <ResponsiveImage src="/images/gp.jpg" />
                  </Box>
                </Hidden>
              </Grid>
              {practice?.initiated ? (
                <EnrolmentStart odscode={practice.code} />
              ) : (
                <Grid item xs={12} md={6}>
                  {isBusy && (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress size={50} />
                    </div>
                  )}
                  {!isBusy && (
                    <>
                      {hasError && !isComplete && error !== 409 && (
                        <RegistrationError errorResponse={errorResponse} />
                      )}
                      {hasError && !isComplete && error === 409 && (
                        <RegisteredError />
                      )}
                      {isComplete && !hasError && <RegistrationComplete />}
                      {!isComplete && (
                        <RegistrationForm
                          onSubmit={handleSubmit}
                          practice={practice}
                          extraValues={{
                            odsCode: `${practice?.code || ""}`,
                            termsVersion: DMUK_TERMS_VERSION,
                            ...values,
                          }}
                        />
                      )}
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </AnimationOnScroll>
        </Container>
      </div>
    </section>
  );
}

export default HeroResultUK;
