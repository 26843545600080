/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { NOMATCH_ODS } from "../../../../core/constants";

function NoMatchRow() {
  const { t } = useTranslation("dmuk");
  return (
    <Box mb={1}>
      <Paper variant="elevation">
        <Box p={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="p"
                color="primary"
                textTransform="uppercase"
                fontWeight={700}
              >
                {t("search.nomatch.title")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm>
              <Typography variant="body2" component="p">
                {t("search.nomatch.para1")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} textAlign="right">
              <Button
                variant="outlined"
                data-testid="cant-find-gp"
                color="primary"
                size="large"
                component={Link}
                to={NOMATCH_ODS}
                fullWidth
              >
                Continue <KeyboardDoubleArrowRightIcon color="inherit" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default NoMatchRow;
