/** @jsxImportSource @emotion/react */
import { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import { useStyles } from "./component.styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import omnigenLogo from "../../OmnigenWhiteOnly.svg";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { LINKS, ROUTES } from "../../core/constants";
import { CONTACT_ADDRESS } from "../../core/constants";

export interface FooterProps {
  companyName: string;
  address: string;
  onPrivacyOpen?: () => void;
}

const Footer = ({
  companyName,
  address,
  onPrivacyOpen,
}: FooterProps): ReactElement => {
  const { t } = useTranslation("global");
  const styles = useStyles();
  return (
    <footer css={styles.footer}>
      <div css={styles.footerTop}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="body1" paragraph component="h4">
                <img alt="Omnigen" src={omnigenLogo} width={150} />
              </Typography>
              <Typography
                variant="body2"
                paragraph
                component="p"
                fontWeight={300}
              >
                {t("companyInfo")}
              </Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md>
                <Typography variant="body1" paragraph component="h4">
                  {t("menu.resources")}
                </Typography>
                <List disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      disableGutters
                      dense
                      component={Link}
                      href={ROUTES.unitedKingdom}
                    >
                      <ListItemText primary={t("menu.dmuk")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      disableGutters
                      dense
                      component={Link}
                      href={ROUTES.southAfrica}
                    >
                      <ListItemText primary={t("menu.dmsa")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      disableGutters
                      dense
                      component={Link}
                      href={ROUTES.contact}
                    >
                      <ListItemText primary={t("menu.contact")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      disableGutters
                      dense
                      component={Link}
                      href={LINKS.omnigenWeb}
                      target="_blank"
                    >
                      <ListItemText primary="Omnigen Biodata" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
            </Hidden>
            <Grid item xs={6} md>
              <Typography variant="body1" paragraph component="h4">
                {t("menu.dmuk")}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                component="address"
                fontWeight={300}
              >
                FAO Discover Me UK,
                <br />
                Omnigen Biodata Ltd
                {CONTACT_ADDRESS.split(",").map((line) => (
                  <Fragment key={`address-line-${line}`}>
                    ,
                    <br />
                    {line}
                  </Fragment>
                ))}
              </Typography>
            </Grid>
            <Grid item xs={6} md>
              <Typography
                variant="body1"
                fontWeight={500}
                paragraph
                component="h4"
              >
                {t("menu.dmsa")}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                component="address"
                fontWeight={300}
              >
                FAO Discover Me SA,
                <br />
                Omnigen Biodata Ltd
                {CONTACT_ADDRESS.split(",").map((line) => (
                  <Fragment key={`address-line-${line}`}>
                    ,
                    <br />
                    {line}
                  </Fragment>
                ))}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div css={styles.footerBottom}>
        <Container>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="body2" component="p" color="white">
                Copyright {companyName} {new Date().getFullYear()}. All rights
                reserved.{" | "}
                <span
                  data-testid="privacy-open"
                  role="button"
                  css={styles.footerPrivacyButton}
                  tabIndex={0}
                  onKeyUp={onPrivacyOpen}
                  onClick={onPrivacyOpen}
                >
                  Omnigen's Privacy Notice
                </span>
              </Typography>
              <Typography variant="body2" component="p" color="white">
                Registered address {address}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                aria-label="LinkedIn"
                color="inherit"
                component={Link}
                href="https://www.linkedin.com/company/omnigen-biodata-ltd"
                target="_blank"
              >
                <LinkedInIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
