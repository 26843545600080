/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import Box from "@mui/material/Box";
import AnimationOnScroll from "react-animate-on-scroll";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";

function PostEnrolmentStrip() {
  const { t } = useTranslation("dmsa");
  return (
    <Strip>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align="inherit"
              color="secondary.contrastText"
            >
              {t("postenrol.pre")}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="inherit"
              gutterBottom
              color="primary"
            >
              {t("faqs.sections.2.5.q")}
            </Typography>

            <Typography variant="body1" component="span">
              <Markdown>{t("faqs.sections.2.5.a")}</Markdown>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeInRight">
            <ResponsiveImage
              src={`/images/${t("postenrol.image")}`}
              alt={t("postenrol.alt")}
              isRaised={false}
            />
          </AnimationOnScroll>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default PostEnrolmentStrip;
