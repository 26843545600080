/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { NHS_NUMBER_URL, LINKS } from "../../../../../../core/constants";

export type EnrolmentStartProps = {
  odscode: string;
};

function EnrolmentStart({ odscode }: EnrolmentStartProps) {
  const { t } = useTranslation("dmuk");
  return (
    <Grid item xs={12} md={6}>
      <Box m={{ md: 4, sm: 0 }} pt={{ lg: 12, md: 4, xs: 0 }}>
        <Typography variant="h5" component="h2" gutterBottom color="success">
          {t("enrolment.title")}
        </Typography>
        <Typography variant="body1">{t("enrolment.para1")}</Typography>
        <Button
          sx={{ marginTop: 2, marginBottom: 2 }}
          variant="outlined"
          color="primary"
          target="_blank"
          href={NHS_NUMBER_URL}
        >
          {t("enrolment.nhsButton")}
        </Button>
        <Typography mb={4} variant="body1">
          {t("enrolment.para2")}
        </Typography>
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          href={`${LINKS.portalUK()}/ods/${odscode}`}
        >
          {t("enrolment.button")}
        </Button>
      </Box>
    </Grid>
  );
}

export default EnrolmentStart;
