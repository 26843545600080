import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import paginate from "paginate-array";

import { ROUTES } from "../../../../core/constants";
import MainLayout from "../../../../layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { searchPracticeThunk } from "../../../../store/practices";
import {
  responseSelector,
  isBusySelector,
} from "../../../../store/practices/selectors";
import { resetRegistration } from "../../../../store/registrations";
import HeroResultsUK from "./components/HeroResultsUK";

function UnitedKingdomResultsScene() {
  const dispatch = useAppDispatch();
  const practices = useAppSelector(responseSelector);
  const isBusy = useAppSelector(isBusySelector);
  const [results, setResults] = useState<any | null>(null);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(resetRegistration());
    if (practices) {
      const collection = paginate(practices, page, 3);
      setResults(collection);
    }
  }, [practices, page, dispatch]);

  const handleSearch = (keyword: string) => {
    dispatch(searchPracticeThunk(keyword));
  };

  if (!practices && !isBusy) {
    return <Navigate to={ROUTES.unitedKingdom} />;
  }

  return (
    <MainLayout>
      <HeroResultsUK
        isBusy={isBusy}
        results={results}
        onPage={(toPage: number) => {
          setPage(toPage);
        }}
        onSearch={handleSearch}
      />
    </MainLayout>
  );
}

export default UnitedKingdomResultsScene;
