import { Navigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../../core/constants";

const PracticeRedirect = () => {
  let { odscode } = useParams();
  return (
    <Navigate
      to={`${ROUTES.unitedKingdom}/${ROUTES.unitedKingdomResults}/${odscode}`}
    />
  );
};

export default PracticeRedirect;
