import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/react";

export const useStyles = () => {
  const theme = useTheme();
  return {
    header: css`
      background-color: ${theme.palette.common.white};
      padding: 25px 0;
      border-bottom: 1px solid ${theme.palette.grey[300]};
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    `,
    headerInner: css`
      display: flex;
      flex-direction: row;
    `,
    logo: css`
      margin: 0 15px 0 0;
      height: 36.5px;
    `,
    nav: css`
      margin: 0 auto 0 0;
    `,
    secondaryNav: css`
      margin-right: 10px;
    `,
    actions: css``,
    navList: css`
      display: flex;
      flex-direction: row;
      list-style: none;
      margin: 0;
      flex-grow: 1;
      padding: 0px;

      li {
        a {
          display: block;
          line-height: 36.5px;
          padding: 0 15px;
        }
      }
    `,
    navListItem: css``,
    navLink: css``,
    drawer: css`
      min-width: 300px;
    `,
  };
};
