/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import AnimationOnScroll from "react-animate-on-scroll";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";
import { ROUTES } from "../../../../core/constants";

function AimsHome() {
  const isFullWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );
  return (
    <Strip variant="primary" arrowDirection="down">
      <Box mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              component="p"
              align="center"
              color="secondary"
            >
              DISCOVER ME
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="center"
              gutterBottom
              color="white"
            >
              What is the Discover Me study?
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <ResponsiveImage src="/images/healthcare.jpg" alt="healthcare" />
          </AnimationOnScroll>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              paragraph
              color="white"
              fontWeight={700}
            >
              By collecting health and genetic information on thousands of
              people, Discover Me aims to help us find out why diseases may
              affect some people more than others. This could help transform
              healthcare.
            </Typography>
            <Typography variant="body1" component="p" color="white">
              As part of Discover Me, you can learn more about your own health,
              supporting you and your doctor to make better decisions about your
              health and care.
            </Typography>
          </Box>
          <div style={{ color: "white" }}>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              component={Link}
              fullWidth={isFullWidth}
              to={ROUTES.participants}
            >
              Discover More <KeyboardDoubleArrowRightIcon color="inherit" />
            </Button>
          </div>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default AimsHome;
