import { createSlice } from "@reduxjs/toolkit";
import { RegistrationsState } from "./types";
import { StoreStatus } from "../../core/types/common.types";
import { registerThunk } from "./thunks";

// Initial State
export const initialState: RegistrationsState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  response: null,
};

// Slice
export const registrationsSlice = createSlice({
  name: "registrations",
  initialState,
  reducers: {
    resetRegistration: () => {
      return initialState;
    },
  },
  extraReducers: {
    [registerThunk.pending.type]: (state, action) => {
      state.status = StoreStatus.BUSY;
      state.values = action.meta.arg;
    },
    [registerThunk.fulfilled.type]: (state, action) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    },
    [registerThunk.rejected.type]: (state, action) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    },
  },
});

// Action creators
export const { resetRegistration } = registrationsSlice.actions;
export * from "./thunks";
export default registrationsSlice.reducer;
