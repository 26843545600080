export type MailjetProspect = {
  firstName: string;
  lastName: string;
  dob: string;
  odsCode: string;
  sex: string;
  email: string;
  postcode: string;
  source: string;
  address: string;
  sms: string;
  termsVersion: string;
  acceptContact: boolean;
  acceptNotices: boolean;
};

const mailjetApi = {
  registerInterest: async (values: MailjetProspect): Promise<any> => {
    try {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          odsCode: values.odsCode,
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
          firstName: values.firstName,
          surname: values.lastName,
          dob: values.dob,
          sms: values.sms,
          source: values.source,
          termsVersion: values.termsVersion,
          termsAccepted: values.acceptNotices,
          contactAccepted: values.acceptContact,
          newsLetterSub: values.acceptContact,
          country: "UK",
        }),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/mailjet/users/new`,
        options
      ).then((response) => {
        return response
          .json()
          .then((data) => ({ ...data, status: response.status }));
      });
      if (response.status !== 200) {
        return new Promise((resolve, reject) => {
          resolve({
            errors: { ...response },
          });
        });
      }

      return new Promise((resolve, reject) => {
        resolve({
          data: { ...response },
        });
      });
    } catch (error) {
      return { errors: [error] };
    }
  },
};

export default mailjetApi;
