import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Strip from "../../components/Strip";
import MainLayout from "../../layouts/Main";
import logoUK from "../../DMFullColourUK.svg";
import logoSA from "../../DMFullColourSA.svg";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import { ROUTES } from "../../core/constants";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

function ParticipantsScene() {
  const isFullWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );
  return (
    <MainLayout>
      <Strip>
        <Box mb={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={10}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                align="center"
                color="primary"
              >
                <span style={{ fontWeight: 100 }}>Participant</span>{" "}
                <strong>Region</strong>
              </Typography>
              <Typography
                variant="h5"
                component="p"
                color="inherit"
                textAlign="center"
              >
                Please select a region:
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md textAlign="center">
            <Card>
              <CardActionArea>
                <CardContent>
                  <Box
                    pl={{ xs: 2, sm: 11 }}
                    pr={{ xs: 3, sm: 11 }}
                    pt={5}
                    pb={5}
                  >
                    <Box mb={4}>
                      <Link to={ROUTES.unitedKingdom}>
                        <img src={logoUK} alt="Discover Me UK" />
                      </Link>
                    </Box>
                    <Button
                      data-testid="dmuk-route"
                      color="primary"
                      variant="outlined"
                      size="large"
                      component={Link}
                      to={ROUTES.unitedKingdom}
                      fullWidth={isFullWidth}
                    >
                      Continue <KeyboardDoubleArrowRightIcon color="inherit" />
                    </Button>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md textAlign="center">
            <Card>
              <CardActionArea>
                <CardContent>
                  <Box
                    pl={{ xs: 2, sm: 11 }}
                    pr={{ xs: 3, sm: 11 }}
                    pt={5}
                    pb={5}
                  >
                    <Box mb={4}>
                      <Link to={ROUTES.southAfrica}>
                        <img src={logoSA} alt="Discover Me SA" />
                      </Link>
                    </Box>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      component={Link}
                      to={ROUTES.southAfrica}
                      fullWidth={isFullWidth}
                    >
                      Continue <KeyboardDoubleArrowRightIcon color="inherit" />
                    </Button>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Strip>
    </MainLayout>
  );
}

export default ParticipantsScene;
