export interface GraphQlErrorLocation {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface GraphQlError {
  path: string[];
  data: string | null;
  errorType: string;
  errorInfo: string | null;
  locations: GraphQlErrorLocation[];
  message: string;
}

export interface PromiseRejection {
  rejectWithValue: any;
}

export interface KeyValues {
  [key: string]: string | number | boolean | any[];
}

export enum StoreStatus {
  BUSY = "busy",
  IDLE = "idle",
  ERROR = "error",
}
