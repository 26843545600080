/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import Container from "@mui/material/Container";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStyles } from "./component.styles";
import { Theme, Interpolation } from "@emotion/react";

export type StripProps = {
  children: ReactNode;
  variant?: "primary" | "light" | "neutral" | "neutralLight";
  arrowDirection?: "up" | "down";
  extraCss?: Interpolation<Theme>;
};

const Strip = ({ children, variant, arrowDirection, extraCss }: StripProps) => {
  const styles = useStyles(variant);
  return (
    <section css={[styles.section, ...[extraCss]]}>
      {arrowDirection && (
        <div
          css={[
            styles.arrow,
            arrowDirection === "down" ? styles.arrowDown : styles.arrowUp,
          ]}
        >
          {arrowDirection === "down" && (
            <KeyboardArrowDownIcon color="inherit" />
          )}
          {arrowDirection === "up" && <KeyboardArrowUpIcon color="inherit" />}
        </div>
      )}
      <Container>{children}</Container>
    </section>
  );
};

export default Strip;
