/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Strip from "../../../../../../components/Strip";
import FAQBlock, { FAQSection } from "../../../../../../components/FAQBlock";
import { STUDY_TEL_UK, STUDY_EMAIL_UK } from "../../../../../../core/constants";

function FAQStrip() {
  const { t } = useTranslation("dmuk");
  const [searchParams] = useSearchParams();
  const qsSection = searchParams.get("fs");
  const qsQuestion = searchParams.get("fq");
  const faqSections = t("faqs.sections", {
    studyEmail: STUDY_EMAIL_UK,
    studyTel: STUDY_TEL_UK,
  }) as FAQSection[];
  return (
    <Strip>
      <Box mb={4}>
        <Typography
          variant="body1"
          component="p"
          gutterBottom
          align="center"
          color="secondary.contrastText"
        >
          {t("faqs.pre")}
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="primary"
        >
          {t("faqs.title")}
        </Typography>
      </Box>
      <FAQBlock
        section={qsSection ? `${parseInt(qsSection) - 1}` : null}
        question={qsQuestion ? `${parseInt(qsQuestion) - 1}` : null}
        sections={Object.values(faqSections).map((faqSection) => {
          return {
            title: faqSection.title,
            questions: Object.values(faqSection).filter(
              (faq) => typeof faq !== "string"
            ),
          };
        })}
      />
    </Strip>
  );
}

export default FAQStrip;
