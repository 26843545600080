import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/react";

export const useStyles = () => {
  const theme = useTheme();
  return {
    strip: css`
      ${theme.breakpoints.up("md")} {
        background: url(/images/dna.jpg) top right no-repeat;
        background-size: auto 140%;
      }
    `,
  };
};
