import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import ScrollTop from "../components/ScrollTop";
import PageTracker from "../components/PageTracker";
import { ROUTES } from "../core/constants";
import ContactScene from "./Contact";
import HomeScene from "./Home";
import ParticipantsScene from "./Participants";
import PortalRegionScene from "./PortalRegion";
import SAScene from "./SouthAfrica";
import UKScene from "./UnitedKingdom";
import PracticeRedirect from "./UnitedKingdom/scenes/Result/components/PracticeRedirect";

function Scenes() {
  return (
    <Router>
      <ScrollTop />
      <PageTracker />
      <Routes>
        <Route path={ROUTES.root} element={<HomeScene />} />
        <Route path={ROUTES.participants} element={<ParticipantsScene />} />
        <Route path={ROUTES.contact} element={<ContactScene />} />
        <Route path={ROUTES.regions} element={<PortalRegionScene />} />
        <Route path={ROUTES.southAfrica} element={<SAScene />} />
        <Route path={`${ROUTES.unitedKingdom}/*`} element={<UKScene />} />
        <Route path={ROUTES.ukResultShort} element={<PracticeRedirect />} />
        <Route path="*" element={<Navigate to={ROUTES.root} replace />} />
      </Routes>
    </Router>
  );
}

export default Scenes;
