export const ROUTES = {
  root: "/",
  participants: "/participants",
  data: "/data",
  research: "/research",
  contact: "/contact",
  regions: "/regions",
  southAfrica: "/dmsa",
  unitedKingdom: "/dmuk",
  unitedKingdomResults: "practices",
  unitedKingdomResult: "practices/:odscode",
  ukResultShort: "ods/:odscode",
};

export const LINKS = {
  portalUK: () => {
    switch (process.env.REACT_APP_ENV) {
      case "dev":
        return "https://uk-dev.discovermestudy.com";
      case "test":
        return "https://uk-test.discovermestudy.com";
      default:
        return "https://uk.discovermestudy.com";
    }
  },
  portalSA: "https://dmsa-portal.omnigenbiodata.co.uk",
  omnigenWeb: "https://www.omnigenbiodata.co.uk",
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const MINAGE = 18;

export const COMPANY_NAME = "Omnigen Biodata";
export const CONTACT_ADDRESS =
  "11 Hope Street Yard, Hope Street, Cambridge, CB1 3NA";
export const COMPANY_ADDRESS = "Four Station Square, Cambridge, CB1 2GE";
export const STUDY_EMAIL_UK = "uk@discovermestudy.com";
export const STUDY_EMAIL_SA = "discovermesa@ukzn.ac.za";

export const STUDY_TEL_UK = "020 3866 8941";
export const STUDY_TEL_SA = "+27 (0) 83 4633 156";

export const DPO_EMAIL = "dpo@omnigenbiodata.co.uk";
export const DISCOVER_ME_SA_FRIENDLY_URL = "www.discovermestudy.com/dmsa";
export const DISCOVER_ME_SA_FULL_URL = "https://www.discovermestudy.com/dmsa";
export const SUPERVISORY_AUTHORITY_URL =
  "https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm";
export const NHS_NUMBER_URL =
  "https://www.nhs.uk/find-nhs-number/what-is-your-name";
export const DMUK_TERMS_VERSION = "V1.0";

export const NOMATCH_ODS = "000000";
