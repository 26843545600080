import { useEffect, useState } from "react";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import Scenes from "./scenes";

const instance = createInstance({
  urlBase: "https://discovermestudy.matomo.cloud",
  siteId: 2,
  configurations: {
    enableJSErrorTracking: true,
  },
});

function App() {
  const [analyticsOn, toggleAnalytics] = useState(
    window.Osano ? window.Osano.cm.analytics : false
  );
  useEffect(() => {
    if (!window.Osano) {
      return;
    }
    window.Osano.cm.addEventListener(
      "osano-cm-consent-saved",
      (consent: any) => {
        if (consent.ANALYTICS === "ACCEPT") {
          toggleAnalytics(true);
        } else if (consent.ANALYTICS === "DENY") {
          toggleAnalytics(false);
        }
      }
    );
    return window.Osano.cm.removeEventListener("osano-cm-consent-saved", null);
  }, []);
  return analyticsOn ? (
    <MatomoProvider value={instance}>
      <Scenes />
    </MatomoProvider>
  ) : (
    <Scenes />
  );
}

export default App;
