import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import Strip from "../../components/Strip";
import MainLayout from "../../layouts/Main";
import logoUK from "../../DMFullColourUK.svg";
import logoSA from "../../DMFullColourSA.svg";
import { LINKS } from "../../core/constants";

function ContactScene() {
  return (
    <MainLayout>
      <Strip>
        <Box mb={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={10}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                align="center"
                color="primary"
              >
                <span style={{ fontWeight: 100 }}>Participant</span>{" "}
                <strong>Portal</strong>
              </Typography>
              <Typography
                variant="h5"
                component="p"
                color="inherit"
                textAlign="center"
              >
                Please select a region:
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md textAlign="center">
            <Card>
              <CardActionArea>
                <CardContent>
                  <Box pl={11} pr={11} pt={5} pb={5}>
                    <Box mb={4}>
                      <Link href={LINKS.portalUK()}>
                        <img src={logoUK} alt="Discover Me UK" />
                      </Link>
                    </Box>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      component={Link}
                      href={LINKS.portalUK()}
                    >
                      Log in to my portal
                    </Button>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md textAlign="center">
            <Card>
              <CardActionArea>
                <CardContent>
                  <Box pl={11} pr={11} pt={5} pb={5}>
                    <Box mb={4}>
                      <Link href={LINKS.portalSA}>
                        <img src={logoSA} alt="Discover Me SA" />
                      </Link>
                    </Box>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      component={Link}
                      href={LINKS.portalSA}
                    >
                      Log in to my portal
                    </Button>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Strip>
    </MainLayout>
  );
}

export default ContactScene;
