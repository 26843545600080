/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import AnimationOnScroll from "react-animate-on-scroll";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";

import logoUK from "../../../../../../DMFullColourUK.svg";
import ResponsiveImage from "../../../../../../components/ResponsiveImage";
import { ODSRow } from "../../../../../../store/practices/types";
import PracticeRow from "../../../../components/PracticeRow";
import UKSearch from "../../../../components/UKSearch";
import { useStyles } from "./component.styles";
import NoMatchRow from "../../../../components/NoMatchRow";

function HeroResultsUK({ results, onSearch, isBusy }: any) {
  const styles = useStyles();
  const { t } = useTranslation("dmuk");

  return (
    <section css={styles.hero}>
      <div css={styles.heroTextWrap}>
        <Container>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box mb={4}>
                  <img src={logoUK} alt={t("title")} width={350} />
                </Box>
                <Hidden mdDown>
                  <Box mb={4}>
                    <ResponsiveImage src="/images/gp.jpg" />
                  </Box>
                </Hidden>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={4}>
                  <Typography
                    variant="h5"
                    component="h2"
                    paragraph
                    color="primary"
                  >
                    {t("search.title2")}
                  </Typography>
                  <UKSearch
                    handleSearch={onSearch}
                    placeholder={t("search.placeholder")}
                    buttonText={t("search.button")}
                  />
                </Box>

                <div style={{ textAlign: "center" }}>
                  {isBusy && <CircularProgress size={50} />}
                </div>
                {!isBusy &&
                  results &&
                  results.data.length > 0 &&
                  results.data.map((practice: ODSRow, index: number) => (
                    <PracticeRow
                      key={`practice-${index}`}
                      practice={practice}
                      delay={index * 300}
                    />
                  ))}
                {!isBusy && results && results.data.length >= 0 && (
                  <NoMatchRow />
                )}
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Container>
      </div>
    </section>
  );
}

export default HeroResultsUK;
