import { createAsyncThunk } from "@reduxjs/toolkit";
import mailjetApi from "../../core/api/mailjet.api";
import { PromiseRejection } from "../../core/types/common.types";
import { RegistrationResponse } from "./types";

/* Create new CryoBox */
export const registerThunk = createAsyncThunk(
  "registrations/register",
  async (values: any, { rejectWithValue }: PromiseRejection) => {
    const response: RegistrationResponse = await mailjetApi.registerInterest(
      values
    );
    if (response.data) {
      return response.data;
    }

    return rejectWithValue(response.errors);
  }
);
