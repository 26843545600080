import { RootState } from "../index";
import { KeyValues, StoreStatus } from "../../core/types/common.types";

export const hasErrorSelector = (state: RootState): boolean =>
  state.registrations.errors ? true : false;

export const errorSelector = (state: RootState): string | number =>
  state.registrations.errors && state.registrations.errors.status
    ? state.registrations.errors.status
    : "";

export const errorResponseSelector = (state: RootState): string | number =>
  state.registrations.errors && state.registrations.errors
    ? state.registrations.errors
    : "";

export const isBusySelector = (state: RootState): boolean =>
  state.registrations.status === StoreStatus.BUSY;

export const valuesSelector = (state: RootState): KeyValues | null =>
  state.registrations.values || null;

export const responseSelector = (state: RootState): any =>
  state.registrations.response || null;
