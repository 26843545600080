/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useStyles } from "./component.styles";
import AnimationOnScroll from "react-animate-on-scroll";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import logoSA from "../../../../DMFullColourSA.svg";
import LanguageSelect from "../LanguageSelect";

function HeroUK() {
  const styles = useStyles();
  const { t } = useTranslation("dmsa");
  const isFullWidth = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );
  return (
    <section css={styles.hero}>
      <div css={styles.heroTextWrap}>
        <Container>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box mb={4}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    textAlign={isFullWidth ? "center" : undefined}
                  >
                    <Grid item xs={isFullWidth ? 12 : "auto"}>
                      <img src={logoSA} alt={t("title")} width={350} />
                    </Grid>
                    <Grid item xs={isFullWidth ? 12 : true}>
                      <LanguageSelect />
                    </Grid>
                  </Grid>
                </Box>
                <Box textAlign={{ xs: "center", sm: "left" }}>
                  <Typography
                    variant="body1"
                    component="span"
                    color="primary"
                    align="inherit"
                  >
                    <Markdown>{t("summary")}</Markdown>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Container>
      </div>
    </section>
  );
}

export default HeroUK;
