import i18next from "i18next";

import global_en from "../../translations/en/global.json";
import global_zu from "../../translations/zu/global.json";

import dmuk_en from "../../translations/en/dmuk.json";
import dmsa_en from "../../translations/en/dmsa.json";
import dmsa_zu from "../../translations/zu/dmsa.json";

import dmsaprivacy_en from "../../translations/en/dmsa-privacy.json";
import dmsaprivacy_zu from "../../translations/zu/dmsa-privacy.json";

import dmukprivacy_en from "../../translations/en/dmuk-privacy.json";

export const setupI18next = (defaultLanguage = "en") => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: defaultLanguage,
    fallbackLng: "en",
    returnObjects: true,
    resources: {
      en: {
        global: global_en,
        dmuk: dmuk_en,
        dmsa: dmsa_en,
        dmsaprivacy: dmsaprivacy_en,
        dmukprivacy: dmukprivacy_en,
      },
      zu: { dmsa: dmsa_zu, dmsaprivacy: dmsaprivacy_zu, global: global_zu },
    },
  });
  return i18next;
};
