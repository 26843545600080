import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/react";

export const useStyles = () => {
  const theme = useTheme();
  return {
    neutralSection: css`
      background: ${theme.palette.grey[200]};
      z-index: 100;
      position: relative;
      padding: 5rem 0;
      ${theme.breakpoints.up("md")} {
        background-image: url(/images/logomark.png);
        background-repeat: no-repeat;
        background-position: calc(50% - 750px) calc(50%);
      }
    `,
    responsiveImg: css`
      max-width: 100%;
    `,
    roundedImg: css`
      border-radius: 2rem;
    `,
  };
};
