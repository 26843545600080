/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Strip from "../../../../../../components/Strip";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PrivacyDialogUK from "../PrivacyDialogUK";

function PrivacyStrip() {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const { t } = useTranslation("dmsa");
  return (
    <>
      <Strip variant="neutral">
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h4"
              component="h2"
              align="left"
              gutterBottom
              color="primary"
              textAlign="center"
            >
              {t("privacy.title")}
            </Typography>

            <Typography
              variant="body1"
              component="p"
              textAlign="center"
              paragraph
            >
              {t("privacy.para1")}
            </Typography>
            <Box textAlign="center">
              <Button
                color="primary"
                variant="outlined"
                size="medium"
                onClick={() => {
                  setIsPrivacyOpen(true);
                }}
              >
                {t("privacy.button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Strip>
      <PrivacyDialogUK
        isOpen={isPrivacyOpen}
        onClose={() => {
          setIsPrivacyOpen(false);
        }}
      />
    </>
  );
}

export default PrivacyStrip;
