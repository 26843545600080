import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/react";

export const useStyles = () => {
  const theme = useTheme();
  return {
    footer: css`
      background-color: ${theme.palette.primary.main};
    `,
    footerTop: css`
      padding-top: 5rem;
      padding-bottom: 5rem;
      color: ${theme.palette.common.white};
    `,
    footerBottom: css`
      border-top: 1px solid ${theme.palette.primary.light};
      padding-top: 2rem;
      padding-bottom: 2rem;
      color: ${theme.palette.common.white};
      background: ${theme.palette.primary.dark};
    `,
    logo: css`
      margin: 0 0 15px 0;
      height: 36.5px;
    `,
    footerPrivacyButton: css`
      cursor: pointer;
    `,
  };
};
