/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ODSRow } from "../../../../store/practices/types";

export type PracticeRowProps = {
  practice: ODSRow;
  delay?: number;
  isSelectable?: boolean;
};

function PracticeRow({
  practice,
  delay = 0,
  isSelectable = true,
}: PracticeRowProps) {
  return (
    <Box mb={1}>
      <Paper variant="elevation">
        <Box p={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="p"
                color="primary"
                fontWeight={700}
              >
                {practice.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm>
              <Typography variant="body2" component="p">
                {practice.address1 && practice.address1 !== practice.name
                  ? `${practice.address1}, `
                  : ""}
                {practice.address2 ? `${practice.address2}, ` : ""}
                {practice.address3 ? `${practice.address3}, ` : ""}
                {practice.town}
              </Typography>
              <Typography variant="body2" component="p">
                {practice.postcode}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} textAlign="right">
              {isSelectable && (
                <Button
                  data-testid="matching-gp"
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  to={practice.code}
                  fullWidth
                >
                  Continue <KeyboardDoubleArrowRightIcon color="inherit" />
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default PracticeRow;
