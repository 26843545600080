/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";

import ResponsiveImage from "../../../../components/ResponsiveImage";
import Strip from "../../../../components/Strip";
import { ROUTES } from "../../../../core/constants";
import { useStyles } from "./GeneticsHome.styles";
import AnimationOnScroll from "react-animate-on-scroll";

function GeneticsHome() {
  const styles = useStyles();
  return (
    <Strip extraCss={styles.strip}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box mb={4} textAlign={{ xs: "center", sm: "left" }}>
            <Typography
              variant="body1"
              component="p"
              align="inherit"
              color="secondary.contrastText"
            >
              HEALTH &amp; GENETICS
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              align="inherit"
              gutterBottom
              color="primary"
            >
              Why study health and genetic information together?
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography
              variant="body1"
              component="p"
              paragraph
              color="inherit"
              fontWeight={700}
            >
              Genetic information is the information held in our DNA that is
              inherited across generations. By connecting this to information on
              health, we can study diseases and conditions in much more detail
              than if we only looked at health or genetic information alone.
              This provides us opportunities to better understand disease,
              including:
            </Typography>
            <Typography
              variant="body1"
              component="div"
              paragraph
              color="inherit"
            >
              <ul>
                <li>Why some diseases affect some people more than others</li>
                <li>Why disease progresses differently in different people</li>
                <li>How to better prevent, manage and diagnose diseases</li>
              </ul>
            </Typography>
          </Box>
          <div>
            <Hidden mdDown>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                component={Link}
                to={ROUTES.participants}
              >
                Discover More <KeyboardDoubleArrowRightIcon color="inherit" />
              </Button>
            </Hidden>
            <Hidden mdUp>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                fullWidth
                component={Link}
                to={ROUTES.participants}
              >
                Discover More <KeyboardDoubleArrowRightIcon color="inherit" />
              </Button>
            </Hidden>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Hidden mdDown>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <ResponsiveImage src="/images/genetics.jpg" alt="Genetics" />
            </AnimationOnScroll>
          </Hidden>
        </Grid>
      </Grid>
    </Strip>
  );
}

export default GeneticsHome;
