import { Navigate, useParams } from "react-router-dom";
import { NOMATCH_ODS, ROUTES } from "../../../../core/constants";
import MainLayout from "../../../../layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  practiceSelector,
  isBusySelector as practicesBusySelector,
  hasErrorSelector as practiceErrorSelector,
} from "../../../../store/practices/selectors";
import {
  isBusySelector,
  responseSelector as regResponseSelector,
  hasErrorSelector as regHasErrorSelector,
  valuesSelector as regValuesSelector,
  errorSelector as regErrorSelector,
  errorResponseSelector,
} from "../../../../store/registrations/selectors";
import { registerThunk } from "../../../../store/registrations";
import HeroResultUK from "./components/HeroResultUK";
import { getByOdsThunk } from "../../../../store/practices";
import { useEffect, useState } from "react";

function UnitedKingdomResultScene() {
  let { odscode } = useParams();
  const [searchOds, setSearchOds] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const regResponse = useAppSelector(regResponseSelector);
  const regHasError = useAppSelector(regHasErrorSelector);
  const regError = useAppSelector(regErrorSelector);
  const regErrorResponse = useAppSelector(errorResponseSelector);

  const practice = useAppSelector(practiceSelector);
  const practicesError = useAppSelector(practiceErrorSelector);
  const practicesBusy = useAppSelector(practicesBusySelector);
  const regValues = useAppSelector(regValuesSelector);

  const handleSubmit = (values: { [x: string]: string }) => {
    dispatch(registerThunk(values));
  };

  useEffect(() => {
    if (
      !searchOds &&
      odscode &&
      odscode !== NOMATCH_ODS &&
      !practicesBusy &&
      !practicesError
    ) {
      setSearchOds(odscode);
      dispatch(getByOdsThunk(odscode));
    }
  }, [odscode, dispatch, practicesBusy, practicesError, searchOds]);

  if (
    !practice &&
    !practicesBusy &&
    practicesError &&
    odscode !== NOMATCH_ODS
  ) {
    return <Navigate to={ROUTES.unitedKingdom} />;
  }

  return (
    <MainLayout>
      <HeroResultUK
        handleSubmit={handleSubmit}
        isBusy={isBusy || practicesBusy}
        isComplete={regResponse ? true : false}
        hasError={regHasError}
        error={regError}
        errorResponse={regErrorResponse}
        practice={practice}
        values={regValues}
      />
    </MainLayout>
  );
}

export default UnitedKingdomResultScene;
