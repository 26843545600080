/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

export interface RegistrationErrorProps {
  errorResponse?: any;
}

function RegistrationError({ errorResponse }: RegistrationErrorProps) {
  const { t } = useTranslation("dmuk");
  const { trackEvent } = useMatomo();
  useEffect(() => {
    trackEvent({
      category: "registration",
      action: "registration-error",
      customDimensions: errorResponse
        ? [
            {
              id: 1,
              value: JSON.stringify(errorResponse),
            },
          ]
        : undefined,
    });
  }, [trackEvent, errorResponse]);
  return (
    <Box mb={4}>
      <Alert severity="error">
        <strong>{t("regform.messages.error.title")}</strong>
        <br />
        {t("regform.messages.error.para1")}
      </Alert>
    </Box>
  );
}

export default RegistrationError;
