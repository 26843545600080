/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { ROUTES } from "../../../../../../core/constants";
import Typography from "@mui/material/Typography";

function RegistrationComplete() {
  const { t } = useTranslation("dmuk");
  const { trackEvent } = useMatomo();
  useEffect(() => {
    trackEvent({ category: "registration", action: "registration-success" });
  }, [trackEvent]);
  return (
    <>
      <Box mb={4}>
        <Typography variant="h5" component="h2" paragraph color="primary">
          {t("regform.messages.success.title")}
        </Typography>
        <Typography variant="body1" component="span" textAlign="inherit">
          <Markdown>{t("regform.messages.success.para1")}</Markdown>
        </Typography>
      </Box>
      <Box textAlign="center">
        <Button
          variant="outlined"
          size="large"
          component={Link}
          to={ROUTES.unitedKingdom}
        >
          {t("regform.messages.success.button")}
        </Button>
      </Box>
    </>
  );
}

export default RegistrationComplete;
