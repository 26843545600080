import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

function PageTracker() {
  const location = useLocation();
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView();
  }, [location.pathname, trackPageView]);

  return null;
}

export default PageTracker;
