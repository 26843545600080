/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { parsePhoneNumber } from "awesome-phonenumber";
import { MINAGE, MONTHS, ROUTES } from "../../../../../../core/constants";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { useMainLayout } from "../../../../../../layouts/Main";

export type FormikValues = {
  [x: string]: string;
};

const yupDobValidation = (value: any, ctx: any) => {
  if (ctx.parent.dobYear && ctx.parent.dobMonth >= 0 && ctx.parent.dobDay) {
    const dobDate = new Date(
      parseInt(ctx.parent.dobYear),
      parseInt(ctx.parent.dobMonth),
      parseInt(ctx.parent.dobDay)
    );
    const diff = moment().diff(dobDate);
    const is18OrOver = Math.floor(moment.duration(diff).asYears()) >= MINAGE;
    return is18OrOver;
  }
  return true;
};

function RegistrationForm({ onSubmit, extraValues, onOpenTerms }: any) {
  const { t } = useTranslation("dmuk");
  const { handleOpenTerms, handleOpenPrivacy } = useMainLayout();
  const { trackEvent } = useMatomo();
  const validationSchema = yup.object().shape({
    acceptNotices: yup
      .boolean()
      .oneOf([true], t("regform.acceptNotices.required")),
    firstName: yup.string().required(t("regform.lname.required")),
    lastName: yup.string().required(t("regform.fname.required")),
    email: yup.string().required(t("regform.email.required")),
    sms: yup
      .string()
      .test("is-mobile", t("regform.sms.required"), (value) => {
        if (!value) {
          return true;
        }
        const pn = parsePhoneNumber(value, "GB");
        return pn.isValid() && pn.isMobile();
      })
      .required(t("regform.sms.required")),
    dobDay: yup
      .number()
      .test("is-valid-date", t("regform.dob.required"), yupDobValidation)
      .required(t("regform.dob.day.required"))
      .min(1, t("regform.dob.day.required"))
      .max(31, t("regform.dob.day.required")),
    dobMonth: yup
      .number()
      .test("is-valid-month", t("regform.dob.required"), yupDobValidation)
      .required(t("regform.dob.month.required"))
      .max(11, t("regform.dob.month.required"))
      .min(0, t("regform.dob.month.required")),
    dobYear: yup
      .string()
      .test("is-valid-year", t("regform.dob.required"), yupDobValidation)
      .required(t("regform.dob.year.required")),
  });

  const formik = useFormik({
    initialValues: {
      acceptNotices: false,
      acceptContact: false,
      firstName: "",
      lastName: "",
      sex: "Male",
      email: "",
      dobDay: "1",
      dobMonth: "",
      dobYear: "",
      address: "-",
      postcode: "-",
      source: "GP",
      sms: "",
      ...extraValues,
    },
    validationSchema,
    onSubmit: (values: FormikValues) => {
      trackEvent({ category: "registration", action: "click-register" });
      onSubmit({
        ...values,
        sms: values.sms,
        dob: moment(
          new Date(
            parseInt(values.dobYear),
            parseInt(values.dobMonth),
            parseInt(values.dobDay)
          )
        ).format("YYYY-MM-DD"),
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom color="success">
          {t("regform.title")}
        </Typography>

        <Typography
          variant="body1"
          component="p"
          paragraph
          color="primary"
          fontWeight={700}
        >
          {t("regform.para1")}
        </Typography>
      </Box>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-basic"
              name="firstName"
              data-testid="firstName"
              label={t("regform.fname.label")}
              variant="outlined"
              fullWidth
              error={
                formik.errors.firstName && formik.touched.firstName
                  ? true
                  : false
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="outlined-basic"
              name="lastName"
              data-testid="lastName"
              label={t("regform.lname.label")}
              variant="outlined"
              fullWidth
              error={
                formik.errors.lastName && formik.touched.lastName ? true : false
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-basic"
              name="email"
              data-testid="email"
              label={t("regform.email.label")}
              variant="outlined"
              fullWidth
              error={formik.errors.email && formik.touched.email ? true : false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-basic"
              name="sms"
              label={t("regform.sms.label")}
              variant="outlined"
              fullWidth
              error={formik.errors.sms && formik.touched.sms ? true : false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sms}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              margin={0}
              color={
                (formik.errors.dobMonth && formik.touched.dobMonth) ||
                (formik.errors.dobDay && formik.touched.dobDay) ||
                (formik.errors.dobYear && formik.touched.dobYear)
                  ? "error"
                  : "primary"
              }
            >
              {t("regform.dob.label")}
            </Typography>
            {formik.errors.dobDay &&
              formik.errors.dobMonth &&
              formik.errors.dobYear && (
                <Typography variant="body1" color="error" component="p">
                  {t("regform.dob.required")}
                </Typography>
              )}
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              id="dobDay"
              name="dobDay"
              label={t("regform.dob.day.label")}
              variant="outlined"
              type="number"
              inputProps={{ maxLength: 2, min: 1, max: 31 }}
              fullWidth
              error={
                (formik.errors.dobMonth && formik.touched.dobMonth) ||
                (formik.errors.dobDay && formik.touched.dobDay) ||
                (formik.errors.dobYear && formik.touched.dobYear)
                  ? true
                  : false
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dobDay}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <FormControl
              fullWidth
              error={
                (formik.errors.dobMonth && formik.touched.dobMonth) ||
                (formik.errors.dobDay && formik.touched.dobDay) ||
                (formik.errors.dobYear && formik.touched.dobYear)
                  ? true
                  : false
              }
            >
              <InputLabel id="dobMonth-label">Month</InputLabel>
              <Select
                labelId="dobMonth-label"
                id="dobMonth"
                data-cy="dobMonth"
                name="dobMonth"
                label={t("regform.dob.month.label")}
                error={
                  (formik.errors.dobMonth && formik.touched.dobMonth) ||
                  (formik.errors.dobDay && formik.touched.dobDay) ||
                  (formik.errors.dobYear && formik.touched.dobYear)
                    ? true
                    : false
                }
                value={formik.values.dobMonth}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {MONTHS.map((month, index) => (
                  <MenuItem key={month} value={index}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4}>
            <FormControl
              fullWidth
              error={
                (formik.errors.dobMonth && formik.touched.dobMonth) ||
                (formik.errors.dobDay && formik.touched.dobDay) ||
                (formik.errors.dobYear && formik.touched.dobYear)
                  ? true
                  : false
              }
            >
              <InputLabel id="dobMonth-label">Year</InputLabel>
              <Select
                labelId="dobYear-label"
                id="dobYear"
                name="dobYear"
                data-cy="dobYear"
                label={t("regform.dob.year.label")}
                error={
                  (formik.errors.dobMonth && formik.touched.dobMonth) ||
                  (formik.errors.dobDay && formik.touched.dobDay) ||
                  (formik.errors.dobYear && formik.touched.dobYear)
                    ? true
                    : false
                }
                value={formik.values.dobYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {Array(100)
                  .fill(0)
                  .map((m, index) => {
                    const year = new Date().getFullYear() - MINAGE - index;
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <FormControl
          error={formik.errors.acceptNotices ? true : false}
          component="fieldset"
          variant="standard"
        >
          <FormGroup>
            <FormControlLabel
              onChange={formik.handleChange}
              control={
                <Checkbox
                  checked={formik.values.acceptNotices ? true : false}
                  name="acceptNotices"
                />
              }
              label={
                <div>
                  <span>I confirm I have read and accept the </span>
                  <Link to="#" onClick={handleOpenTerms}>
                    {" "}
                    terms &amp; conditions
                  </Link>
                  <span> and </span>
                  <Link to="#" onClick={handleOpenPrivacy}>
                    privacy notice
                  </Link>
                </div>
              }
            />
          </FormGroup>{" "}
          {formik.errors.acceptNotices && (
            <FormHelperText>
              {t("regform.acceptNotices.required")}
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box mb={2}>
        <FormGroup>
          <FormControlLabel
            name="acceptContact"
            onChange={formik.handleChange}
            control={
              <Checkbox
                checked={formik.values.acceptContact ? true : false}
                name="acceptContact"
              />
            }
            label="I confirm I agree to be contacted to participate in future research studies"
          />
        </FormGroup>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            component={Link}
            to={ROUTES.unitedKingdomResults}
          >
            {t("regform.buttons.cancel")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            type="submit"
            disabled={!formik.isValid}
          >
            {t("regform.buttons.submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default RegistrationForm;
