/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Strip from "../../../../components/Strip";
import FAQBlock, { FAQSection } from "../../../../components/FAQBlock";
import { STUDY_EMAIL_SA, STUDY_TEL_SA } from "../../../../core/constants";

function FAQStrip() {
  const { t } = useTranslation("dmsa");
  const [searchParams] = useSearchParams();
  const qsSection = searchParams.get("fs");
  const qsQuestion = searchParams.get("fq");

  const sections = t("faqs.sections", {
    studyEmail: STUDY_EMAIL_SA,
    studyTel: STUDY_TEL_SA,
  }) as FAQSection[];
  return (
    <Strip>
      <Box mb={4}>
        <Typography
          variant="body1"
          component="p"
          gutterBottom
          align="center"
          color="secondary.contrastText"
        >
          {t("faqs.pre")}
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="primary"
        >
          {t("faqs.title")}
        </Typography>
      </Box>

      <FAQBlock
        section={qsSection ? `${parseInt(qsSection) - 1}` : null}
        question={qsQuestion ? `${parseInt(qsQuestion) - 1}` : null}
        sections={Object.values(sections).map((section) => {
          return {
            title: section.title,
            questions: Object.values(section).filter(
              (faq) => typeof faq !== "string"
            ),
          };
        })}
      />
    </Strip>
  );
}

export default FAQStrip;
